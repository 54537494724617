import { usePermissionService } from 'admin-portal-shared-services';

function CheckIfUserHasOnePermission(userScope: Array<string>): boolean {
  const hasAccess: Array<boolean> = userScope.map(scope => {
    return usePermissionService().has(scope);
  });

  return hasAccess.includes(true);
}

export default CheckIfUserHasOnePermission;
