export const DEFAULT_PLP_PAGE_SIZE = 25;
export const DEFAULT_IMAGE_PLACEHOLDER_URL =
  'https://cms-non-prod.global.ssl.fastly.net/media/06-24-2021-b7e68d65-14bd-4efb-a083-e2874bb67252-f3224a9b.jpeg';
export const allCountries = [
  'AQ',
  'AR',
  'BB',
  'BE',
  'BO',
  'BR',
  'CA',
  'CL',
  'CO',
  'DE',
  'DO',
  'EC',
  'ES',
  'GB',
  'GH',
  'HN',
  'ID',
  'KR',
  'MX',
  'NL',
  'PA',
  'PE',
  'PH',
  'PY',
  'SV',
  'TZ',
  'UG',
  'US',
  'UY',
  'ZA'
];

export const MIN_VALID_DATE = {
  year: 1899,
  month: 1,
  day: 1
};

export const LEGACY_CLASS_NAME = 'Legacy Product Class';
export const PREFERRED_LANGUAGE = 'PIM_PreferredLanguage';
export const ALL_VENDORS = 'ALL_VENDORS';
export const CSV_GUIDELINES_URL =
  'https://anheuserbuschinbev.sharepoint.com/:w:/s/b2bengineering/Ebaap1VEmo1Mpqjshotw5F4BFx1h9G2cCN8GJxEZTvdQCg?e=ScJTHa';
export const AUDIT_LOG_APP_ID = 'PIM';
