import { useLogService } from 'admin-portal-shared-services';
import React from 'react';
import ReactDOM from 'react-dom';
import singleSpaReact from 'single-spa-react';
import App from './App';
import { setLocaLFeatureToggles, setupLocalEnv } from './setupLocal';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore

if (process.env.NODE_ENV === 'development') {
  if (!window.Cypress && process.env.STANDALONE) {
    setupLocalEnv();
  }
  if (!window.Cypress) {
    setLocaLFeatureToggles();
  }
}

const reactLifecycles = singleSpaReact({
  React,
  ReactDOM,
  rootComponent: App,
  errorBoundary(error: Error) {
    useLogService().error(error);
    return <></>;
  }
});

export const { bootstrap, mount, unmount } = reactLifecycles;
