import { getUserCountries } from '@utils/countries';
import { createStore } from 'effector';
import { initializeZoneManagementStore, setSelectedCountry } from './ZoneManagementEvents';
import ZoneManagementState from './ZoneManagementState';

const initialState: ZoneManagementState = {
  hasLoadedCountries: false,
  selectedCountry: '',
  countries: []
};

const zoneManagementStore = createStore(initialState)
  .on(setSelectedCountry, (state, { country }) => {
    localStorage.setItem('selectedCountry', country);
    return {
      ...state,
      selectedCountry: country
    };
  })
  .on(initializeZoneManagementStore, (state, { countries }) => {
    const selectedCountry = localStorage.getItem('selectedCountry');

    const currentCountry =
      selectedCountry && countries.includes(selectedCountry) ? selectedCountry : countries[0];

    if (!selectedCountry) {
      localStorage.setItem('selectedCountry', currentCountry);
    }

    return {
      countries,
      selectedCountry: currentCountry,
      hasLoadedCountries: true
    };
  });

getUserCountries().then(countries => {
  initializeZoneManagementStore({ countries });
});

export default zoneManagementStore;
