import { getCluster } from '@utils/countries';
import { AxiosInstance } from 'axios';
import { VENDOR_APP_TIER_BASE_URL } from '../../../config/baseUrls';
import restClient from '../RestClient';

interface Params {
  baseURL: string;
  useDeprecatedVersion?: boolean;
  country: string;
}

const PACT_MOCK_SERVER_PORT = 4000;
const PACT_MOCK_SERVER_BASE_URL = `http://127.0.0.1:${PACT_MOCK_SERVER_PORT}`;

export default async function vendorServiceClient({
  baseURL = '',
  useDeprecatedVersion = false,
  country
}: Params): Promise<AxiosInstance> {
  if (useDeprecatedVersion) {
    return restClient(VENDOR_APP_TIER_BASE_URL);
  }
  const cluster = await getCluster(country);
  return restClient(`${baseURL}/api${cluster}/v1/vendors`);
}
