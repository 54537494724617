import RequestVendorByCommand from '@/domains/command/RequestVendorByCommand';
import vendorServiceClient from '../../clients/rest/vendor/VendorServiceClient';
import { BusinessModel } from '../../domains/BusinessModel';
import RequestVendorCommand from '../../domains/command/RequestVendorCommand';
import Page from '../../domains/Page';
import Vendor from '../../domains/Vendor';
import { ContentVendorResponse } from './domains/ContentVendorResponse';
import { VendorResponse } from './domains/VendorResponse';

export async function loadVendors(command: RequestVendorCommand): Promise<Page<Vendor>> {
  const serviceModelParameter = command.businessModel
    ? `&serviceModel=${command.businessModel}`
    : '';

  const partialLegalNameParameter = command.partialLegalName
    ? `&partialLegalName=${command.partialLegalName}`
    : '';

  const vendorIdParameter = command.vendorId ? `&vendorId=${command.vendorId}` : '';

  const pageSizeParameter = command.pagination?.pageSize
    ? `&pageSize=${command.pagination.pageSize}`
    : '';

  const endpoint = `/v2?page=0${pageSizeParameter}${serviceModelParameter}${partialLegalNameParameter}${vendorIdParameter}`;
  const newCountryHeaders = {
    headers: {
      country: command.country
    }
  };
  const restClient = await vendorServiceClient({
    useDeprecatedVersion: true,
    country: command.country
  });
  return restClient
    .get<ContentVendorResponse>(endpoint, newCountryHeaders)
    .then(response => ({
      pagination: { ...command.pagination, totalItems: response.data.content.length },
      items: mapVendorResponseToVendor(response.data.content)
    }))
    .catch(error => {
      throw error;
    });
}

export const getAllVendors = async (command: RequestVendorCommand): Promise<Page<Vendor>> => {
  const vendorIdParameter = command.vendorId ? `&vendorId=${command.vendorId}` : '';
  const pageSizeParameter = command.pagination?.pageSize
    ? `&pageSize=${command.pagination.pageSize}`
    : '';
  const currentPageParameter = command?.pagination?.currentPage
    ? command.pagination.currentPage
    : 0;

  const endpoint = `/v2/?page=${currentPageParameter}${pageSizeParameter}${vendorIdParameter}`;

  const newCountryHeaders = {
    headers: {
      countries: command.country
    }
  };

  const restClient = await vendorServiceClient({
    useDeprecatedVersion: false,
    country: command.country
  });

  return restClient
    .get<ContentVendorResponse>(endpoint, newCountryHeaders)
    .then(response => {
      return {
        pagination: {
          ...response.data.pagination
        },
        items: mapVendorResponseToVendor(response.data.content)
      };
    })
    .catch(error => {
      throw error;
    });
};

export async function loadVendorById(command: RequestVendorCommand): Promise<Vendor> {
  const pageSizeParameter = command.pagination?.pageSize
    ? `&pageSize=${command.pagination.pageSize}`
    : '';
  const endpoint = `/v2/?vendorId=${command.vendorId}&page=0${pageSizeParameter}`;
  const newCountryHeaders = {
    headers: {
      countries: command.country
    }
  };

  const restClient = await vendorServiceClient({
    useDeprecatedVersion: false,
    country: command.country
  });

  return restClient
    .get<ContentVendorResponse>(endpoint, newCountryHeaders)
    .then(response => {
      if (response?.data?.content) {
        return convertResponseToVendor(response.data.content[0]);
      }
      return null;
    })
    .catch(() => {
      return null;
    });
}

export async function loadVendorBy(
  command: RequestVendorByCommand,
  page = 0
): Promise<Page<Vendor>> {
  const defaultPageSize = 50;

  const endpoint = `/v2/search?page=${page}&pageSize=${defaultPageSize}`;

  const restClient = await vendorServiceClient({
    country: command.country
  });

  const headers = {
    country: command.country
  };

  return restClient
    .post(endpoint, command.bodyRequest, { headers })
    .then(response => ({
      pagination: {
        ...{
          currentPage: page,
          totalItems: response.data.content.length,
          totalPages: response.data.pagination.totalPages,
          pageSize: defaultPageSize
        }
      },
      items: mapVendorResponseToVendor(response.data.content)
    }))
    .catch(error => {
      throw error;
    });
}

export const convertResponseToVendor = (vendorResponse: VendorResponse): Vendor => ({
  id: vendorResponse.vendorId,
  name:
    vendorResponse?.displayName?.length > 0 ? vendorResponse.displayName : vendorResponse.legalName,
  businessModel: vendorResponse.serviceModel as BusinessModel,
  country: vendorResponse.country
});

export const mapVendorResponseToVendor = (items: Array<VendorResponse>): Array<Vendor> =>
  items.map((item: VendorResponse) => convertResponseToVendor(item));
