const permissionConst: { [key: string]: string } = {
  PRODUCT_CREATION_WRITE: 'AdminPortalPIM.ProductCreationWizard.Write',
  PRODUCT_DETAILS_PAGE_WRITE: 'AdminPortalPIM.ProductDetailPage.Write',
  PRODUCT_DETAILS_PAGE_READ: 'AdminPortalPIM.ProductDetailPage.Read',
  ATTRIBUTES_WRITE: 'AdminPortalPIM.Attributes.Write',
  ATTRIBUTES_READ: 'AdminPortalPIM.Attributes.Read',
  CATEGORY_DETAILS_WRITE: 'AdminPortalPIM.CategoryDetailPage.Write',
  CATEGORIES_WRITE: 'AdminPortalPIM.Categories.Write',
  CATEGORIES_READ: 'AdminPortalPIM.Categories.Read',
  PRODUCTS_WRITE: 'AdminPortalPIM.Products.Write',
  PRODUCTS_READ: 'AdminPortalPIM.Products.Read',
  PRODUCTS_EDIT: 'AdminPortalPIM.Products.Edit',
  ALL_COUNTRIES: 'AdminPortalPIM.Countries.All',
  ALL_VENDORS: 'AdminPortalPIM.Vendors.All'
};

export default permissionConst;
