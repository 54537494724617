import MessageMap from '../domains/MessageMap';

const ptBR: MessageMap = {
  ProductDetailsRoute: {
    PAGE_LAYOUT_CAPTION: 'Produtos',
    CURRENT_PAGE_TITLE: 'Detalhes',
    FILL_FIELD: 'preencha o campo: {labels}',
    FILL_FIELDS: 'preencha os campos: {labels}',
    ALERT_ERROR_MESSAGE:
      'Não há nenhum {languageAndfieldNamesWithError} registrado para este produto. Por favor, {fieldLabelsWithError}.',
    NAME: 'nome',
    PRODUCT_TITLE: 'título do produto',
    STATUS: 'status',
    IMAGES: 'imagem',
    DESCRIPTION: 'descrição',
    PRODUCT_DESCRIPTION: 'descrição do produto',
    ATTRIBUTES: 'atributos',
    MANUFACTURER: 'fabricante',
    LANGUAGE_AND_FIELD: '{field} em {language}',
    AND: 'e'
  },
  UpdateProductStatusModal: {
    ENABLED_STATUS_LABEL: 'Habilitado',
    DISABLED_STATUS_LABEL: 'Desabilitado',
    SUBMIT_BUTTON_LABEL: 'SALVAR',
    CANCEL_BUTTON_LABEL: 'CANCELAR'
  },
  ProductDetailsHeader: {
    CREATED_LABEL: 'Criado em',
    CLASS_LABEL: 'Classe',
    LAST_EDITED_LABEL: 'Última edição',
    LAST_EDITED_BY_LABEL: 'Última edição por'
  },
  EditGeneralInformationModal: {
    TITLE: 'Informação do produto',
    SUBMIT_BUTTON_LABEL: 'SALVAR',
    CANCEL_BUTTON_LABEL: 'CANCELAR',
    REQUIRED_VALIDATION_MESSAGE: 'Este campo é obrigatório'
  },
  UpdateBulkProductsManufacturerUseCase: {
    SUCCESS_NOTIFICATION: 'Atualizado fabricante dos SKUs',
    FAILED_NOTIFICATION: 'Falha na atualização de SKUs',
    ERROR_NOTIFICATION: 'Não foi possível atualizar os produtos',
    MAX_SELECTED_ERROR:
      'O PIM oferece suporte à edição em massa de até 150 produtos de uma só vez. Por favor, desmarque alguns produtos.'
  },
  EditButtonGeneralInformation: {
    EDIT_BUTTON: 'EDITAR'
  },
  EditButtonAttributeCardView: {
    EDIT_BUTTON: 'EDITAR'
  },
  ProductImageUploaderView: {
    EDIT_LABEL_BUTTON: 'Editar',
    UPLOAD_MAIN_PRODUCT_IMAGE: 'Carregar a imagem principal do produto.',
    THUMBNAILS_MUST_JPG_PNG_FORMAT:
      'Sua imagem deve estar no formato JPG ou PNG e ter menos de 1MB.',
    COPY_BUTTON_TEXT: 'Copiar a URL da imagem',
    SAVE_BUTTON: 'Salvar',
    CANCEL_BUTTON: 'Cancelar',
    COPY_URL_TOOLTIP: 'URL da imagem copiada!'
  },
  ImageCard: {
    TOOLTIP_ERROR_MESSAGE:
      'Domínio ou endereço de imagem inválido. Por favor, carregue uma nova imagem.'
  },
  ImageCardView: {
    UPLOAD_PRODUCT_IMAGE: 'Carregar a imagem do produto.',
    THUMBNAILS_INFORMATION: 'Sua imagem deve estar no formato JPG ou PNG e ter menos de 1MB.',
    COPY_URL_TOOLTIP: 'URL da imagem copiada!'
  },
  GeneralInformationCardView: {
    ENABLED_STATUS_LABEL: 'Habilitado',
    DISABLED_STATUS_LABEL: 'Desabilitado',
    NOT_FOUND: 'Não encontrado.'
  },
  ProductInformationCardView: {
    PRODUCT_INFORMATION_CARD_TITLE: 'Informação do produto',
    SUPPLIER_LABEL: 'Fornecedor',
    CATEGORY_LABEL: 'Classe',
    SKU_INPUT_LABEL: 'SKU *',
    SKU_TOOLTIP_MESSAGE:
      'SKU (unidade de manutenção de estoque) é o código identificador único usado para distinguir produtos a serem vendidos.',
    PRODUCT_TITLE_TOOLTIP_MESSAGE:
      'Este é o nome que aparecerá em nosso Marketplace. Por exemplo: Nome da cerveja 355 ml lata',
    PRODUCT_TITLE_LABEL: 'Título do produto *',
    PRODUCT_DESCRIPTION_LABEL: 'Descrição do produto',
    PRODUCT_VARIATION_ALREADY_EXIST:
      'Esta variação de produto já existe em nosso banco de dados. Se esta for uma variação de produto diferente com os mesmos valores de atributo, ela deverá ter um título e SKU diferentes.',
    THE_TITLE_ALREADY_BEING_USED: 'O título já está sendo usado',
    THE_SKU_ALREADY_EXISTS: 'Este SKU já existe',
    FIELD_STATUS_LABEL: 'Status'
  },
  EditGeneralInformationFieldset: {
    PRODUCT_TITLE_LABEL: 'Título do produto *',
    PRODUCT_DESCRIPTION_LABEL: 'Descrição do produto',
    CATEGORY_LABEL: 'Classe',
    MANUFACTURER: 'Fabricante *'
  },
  DeleteProductImageUseCase: {
    SUCCESS_NOTIFICATION: 'Imagem do produto deletada.'
  },
  GeneralInformationCard: {
    PRODUCT_INFO: 'Informação do produto',
    PRODUCT_TITLE: 'Título do produto *',
    PRODUCT_DESCRIPTION: 'Descrição do produto',
    MANUFACTURER: 'Fabricante *',
    STATUS: 'Status *'
  },
  AttributesCard: {
    CARD_TITLE: 'Atributos'
  },
  EditAttributesModalView: {
    TITLE: 'Atributos',
    SUBMIT_BUTTON_LABEL: 'Salvar',
    CANCEL_BUTTON_LABEL: 'Cancelar',
    REQUIRED_FIELD_MESSAGE: 'Este campo é obrigatório',
    INVALID_DATE_MESSAGE: 'Data inválida'
  },
  ProductHeaderTooltipMessages: {
    CREATED_AT: 'Data criada no PIM',
    UPDATED_AT: 'Data em que o produto foi editado pela última vez.',
    UPDATED_BY: 'O usuário que editou o produto pela última vez.'
  },
  AlertAttributeRequiredView: {
    CHECK_REQUIRED_FIELD_ABLE_MAKE_PRODUCT_ACTIVE:
      'Verifique os campos obrigatórios para habilitar esse produto.'
  },
  ProductListRoute: {
    PAGE_LAYOUT_TITLE: 'Produtos',
    PAGE_LAYOUT_CAPTION: 'Produtos'
  },
  AddProductButton: {
    ADD_PRODUCT_BUTTON_LABEL: '+ ADICIONAR PRODUTO',
    SELECT_VENDOR_TOOLTIP: 'Por favor, selecione um vendedor antes de criar um produto'
  },
  AutoCompleteSearchView: {
    SEARCH_SKU_ONLY: 'Procurar por SKU',
    SEARCH_SKU_AND_TITLE: 'Pesquise digitando ou colando uma lista de títulos de produtos ou SKUs',
    CLEAR_ALL: 'Limpar'
  },
  CreateProductModalView: {
    TITLE: 'Novo produto',
    LABEL_SUBMIT_BUTTON: 'Criar',
    SERVICE_MODEL: 'Modelo de negócio',
    SUB_TITLE_HEADER: 'Selecione o modelo de negócio ideal para você.',
    SELECT_CLASS: 'Selecionar classe',
    SELECT_CLASS_DESCRIPTION:
      'Selecione uma classe da lista abaixo. É ela que vai definir os atributos do seu produto.',
    CLASS: 'Classe',
    LABEL_CANCEL_BUTTON: 'Cancelar',
    LABEL_BACK_BUTTON: 'Voltar',
    NEXT: 'Próximo',
    SELECTED_TEXT: 'Selecionado',
    SELECTED_CLASS: 'Selecionado: {name}',
    SUPPLIER_LABEL: 'Fornecedor',
    SELECT_A_CLASS: 'Selecione uma classe',
    SELECT_CLASS_SUBTITLE:
      'Este novo produto herdará todos os campos de atributos da Classe selecionada.'
  },
  VendorSelector: {
    INPUT_LABEL: 'Selecionar o fornecedor',
    INPUT_PLACEHOLDER: 'Fornecedor'
  },

  CategoryOptionsLoaderUseCase: {
    UNABLE_LOAD_CATEGORY_LIST: 'Não foi possível carregar a lista de classes dos produtos'
  },
  LoadCategoryChildren: {
    UNABLE_LOAD_CATEGORY_LIST: 'Não foi possível carregar a lista de classes dos produtos'
  },
  CategorySelector: {
    SECTION_TITLE: 'Selecionar classe',
    SECTION_DESCRIPTION:
      'Selecione uma classe da lista abaixo. É ela que vai definir os atributos do seu produto.',
    INPUT_LABEL: 'Selecionar classe',
    INPUT_PLACEHOLDER: 'Classe'
  },
  DownloadCsvPlpView: {
    BUTTON_LABEL: 'BAIXAR CSV',
    DOWNLOAD_ALL: 'BAIXAR TUDO',
    TOOLTIP_DOWNLOAD_ALL: 'Você fará o download de um CSV com {totalNumber} produto(s)',
    TOOLTIP_MAX_PRODUCTS:
      'Você não pode baixar mais de 2.000 produtos de uma só vez. Use os filtros e a barra de pesquisa para restringir sua pesquisa.',
    DOWNLOADING_CSV_LABEL: 'Baixando'
  },
  ProductTable: {
    HEADER_COLUMN_TITLE: 'Título do produto',
    HEADER_COLUMN_SKUNUMBER: 'SKU',
    HEADER_COLUMN_BRAND: 'Marca',
    HEADER_COLUMN_VENDOR: 'Nome do vendedor',
    HEADER_COLUMN_MODEL: 'Modelo de serviço',
    HEADER_COLUMN_CATEGORY: 'Classe',
    HEADER_COLUMN_STATUS: 'Status',
    HEADER_COLUMN_LASTUPDATED: 'Última edição',
    HEADER_COLUMN_CREATEDAT: 'Criado em',
    HEADER_COLUMN_COUNTRY: 'País',
    HEADER_COLUMN_MANUFACTURER: 'Fabricante',
    TOOLTIP_COLUMN_SKUNUMBER:
      'SKU (unidade de manutenção de estoque) é o código identificador único usado para distinguir produtos a serem vendidos.',
    TOOLTIP_COLUMN_BRAND: 'O nome do marca do produto',
    TOOLTIP_COLUMN_VENDOR: 'O nome do vendedor',
    TOOLTIP_COLUMN_MODEL: 'O nome do modelo de serviço',
    TOOLTIP_COLUMN_CATEGORY: 'Classificação do produto',
    TOOLTIP_COLUMN_LASTUPDATED: 'Data em que o produto foi editado pela última vez.',
    TOOLTIP_COLUMN_CREATEDAT: 'Data da criação do produto.',
    TOOLTIP_COLUMN_COUNTRY: 'País de origem do produto',
    TOOLTIP_COLUMN_MANUFACTURER: 'Nome do fabricante do produto.',
    EMPTY_VENDOR_MESSAGE: 'O usuário não tem vendor associado',
    MISSING_INFORMATION: 'Há campos não preenchidos'
  },
  ModalExportCsv: {
    DESCRIPTION: 'Descrição',
    IMAGE: 'Url da imagem'
  },
  ModalExportCsvView: {
    CANCEL_BUTTON: 'CANCELAR',
    DOWNLOAD_BUTTON: 'BAIXAR',
    MODAL_TITLE: 'Baixar',
    COUNT_PRODUCTS: 'produtos na fila',
    SELECT_FIELDS: 'Selecionar campos',
    EMPTY_PRODUCTS_ERROR: 'Você deve ter pelo menos um produto na fila.',
    MAX_PRODUCTS_ERROR: 'Quantidade de mais de 2.000 produtos ultrapassados.'
  },
  CheckboxAttributesView: {
    ALL_FIELDS: 'Todos os campos',
    NON_EDITABLE: 'Não editável',
    LEGACY: ' (Legado)'
  },
  CountrySelector: {
    INPUT_LABEL: 'Selecionar país'
  },
  ProductWizardModeSelectorView: {
    THREE_P_L_MODE_SUBTITLE: 'Vendido pela ABI e entregue por um fornecedor.',
    ONE_P_THREE_L_MODE_TITLE: '1P3L',
    ONE_P_THREE_L_MODE_SUBTITLE: 'Vendido pela ABI e entregue por um fornecedor.'
  },
  EditProductTableAction: {
    ALL_FIELDS_DISABLED_TOOLTIP:
      'Você precisa selecionar pelo menos um produto para ativar a edição em massa',
    ONE_FIELD_SELECTED_TOOLTIP:
      'Você só pode atualizar uma informação por vez. Salve ou cancele as alterações para continuar editando seus produtos',
    ENABLED: 'Habilitado',
    DISABLED: 'Desabilitado'
  },
  EditProductTableActionView: {
    CANCEL_BUTTON_LABEL: 'Cancelar',
    SAVE_BUTTON_LABEL: 'Salvar alterações',
    SELECT_PLACEHOLDER: 'Alterar status',
    ENABLED: 'Habilitado',
    DISABLED: 'Desabilitado',
    HAS_SELECTED:
      'Você só pode atualizar uma informação por vez. Salve ou cancele as alterações para continuar editando seus produtos.',
    CHANGE_MANUFACTURER: 'Alterar fabricante',
    PRODUCT_COUNTER: '{totalProductsSelected} produto(s) selecionado(s)'
  },
  Filters: {
    APPLY_BUTTON_LABEL: 'APLICAR',
    CANCEL_BUTTON_LABEL: 'CANCELAR'
  },
  FilterChipList: {
    PRODUCT_STATUS_FILTER: 'Status',
    PRODUCT_STATUS_FILTER_ENABLED: 'Habilitado',
    PRODUCT_STATUS_FILTER_DISABLED: 'Desabilitado',
    PRODUCT_CREATED_DATE_FILTER: 'Criado em',
    PRODUCT_START_DATE_FILTER: 'Data inicial',
    PRODUCT_END_DATE_FILTER: 'Data final',
    WITH_PROBLEMS: 'Itens com erro'
  },
  FiltersModal: {
    APPLY_BUTTON: 'Aplicar',
    CANCEL_BUTTON: 'Cancelar'
  },
  ProductManufacturerFilter: {
    EMPTY_MANUFACTURER: 'Insira algo primeiro para ver os resultados.'
  },
  ProductFilters: {
    FILTER: 'Filtros',
    FILTER_BY_PRODUCT_TITLE: 'Buscar título do produto',
    PRODUCT_TITLE_FILTER: 'Título do produto',
    PRODUCT_STATUS_FILTER: 'Status',
    PRODUCT_STATUS_FILTER_ENABLED: 'Habilitado',
    PRODUCT_STATUS_FILTER_DISABLED: 'Desabilitado',
    PRODUCT_HAS_ERROR_FILTER: 'Itens com erro',
    PRODUCT_HAS_ERROR_FILTER_ENABLED: 'Sim',
    PRODUCT_HAS_ERROR_FILTER_DISABLED: 'Não',
    PRODUCT_CREATED_DATE_FILTER: 'Criado em',
    PRODUCT_START_DATE_FILTER: 'Data inicial',
    PRODUCT_END_DATE_FILTER: 'Data final',
    PRODUCT_COUNTRY_FILTER: 'País',
    PRODUCT_MANUFACTURER_FILTER: 'Fabricante',
    PRODUCT_MANUFACTURER_FILTER_SEARCH: 'Busca',
    PRODUCT_HAS_IMAGE_FILTER: 'Imagem do produto',
    PRODUCT_HAS_IMAGE_FILTER_ENABLED: 'Com imagem',
    PRODUCT_HAS_IMAGE_FILTER_DISABLED: 'Sem imagem'
  },
  ProductCreatedDateFilterView: {
    INVALID_DATE_ERROR: 'Data inválida',
    MAX_START_DATE_ERROR: 'A data de término não pode ser posterior à data atual',
    MAX_START_DATE_ERROR_WITH_END_DATE: 'A data de início não deve ser posterior à data de término',
    MIN_END_DATE_ERROR_WITH_START_DATE: 'A data de término não deve ser anterior à data de início',
    MIN_END_DATE_ERROR: 'A data de término não deve ser anterior à 01/01/1899',
    MIN_START_DATE_ERROR: 'A data de início não deve ser anterior à 01/01/1899',
    MAX_END_DATE_ERROR: 'A data de término não pode ser posterior à data atual.',
    START_DATE: 'Data inicial',
    END_DATE: 'Data final'
  },
  ProductTablePagination: {
    ROWS_PER_PAGE_LABEL: 'linhas por página'
  },
  SearchProductButton: {
    SEARCH: 'Busca'
  },
  StatusTableCellView: {
    ENABLED: 'Habilitado',
    DISABLED: 'Desabilitado'
  },
  SkuTableCell: {
    TOOLTIP_COPY_TEXT: 'Copiado!'
  },
  UploadCsvModalView: {
    TITLE: 'Carregar CSV',
    DRAG_AND_DROP_MESSAGE: 'Arraste e solte o arquivo CSV aqui ou',
    SELECT_FILE_BUTTON: 'Selecionar arquivo CSV',
    DESCRIPTION: 'Carregar descrição',
    BUTTON_CANCEL: 'Cancelar',
    BUTTON_UPLOAD: 'Carregar CSV',
    ONLY_CSV_SUPPORT: 'Oferecemos suporte apenas para arquivos CSV.',
    COUNTRY_SELECTOR_COUNTRY: 'País',
    MODAL_TEXT:
      'Selecione um arquivo CSV que deseja carregar e envie várias atualizações de produtos de uma só vez. Certifique-se de seguir as ',
    UPLOAD_GUIDELINES: 'diretrizes de upload',
    DESCRIPTION_PLACEHOLDER: 'Insira o texto aqui...'
  },
  BulkOperationsPage: {
    PAGE_TITLE: 'Edição em grupo',
    DOWNLOADS_TAB: 'Downloads',
    UPLOADS_TAB: 'Uploads',
    HISTORY_CARD: 'História'
  },
  UploadsTabView: {
    BUTTON: 'Carregar Arquivo',
    TITLE: 'História'
  },
  BulkDownloadTable: {
    HEADER_COLUMN_DATE: 'Data',
    HEADER_COLUMN_STATUS: 'Status',
    HEADER_COLUMN_DESCRIPTION: 'Descrição',
    HEADER_COLUMN_ERRORDESCRIPTION: 'Descrição do erro',
    HEADER_COLUMN_UPLOADDESCRIPTION: 'Descrição do upload',
    HEADER_COLUMN_DOWNLOAD: 'Ação',
    HEADER_COLUMN_COUNTRY: 'País',
    HEADER_COLUMN_TOTALROWS: 'Total de linhas',
    HEADER_COLUMN_FAILEDROWS: 'Linhas não carregadas',
    STATUS_FAILED_IMPORT_UPLOAD: 'Falha',
    STATUS_FAILED_ROWS: 'Linhas não carregadas',
    STATUS_FAILED: 'Falha',
    STATUS_DONE: 'Concluído',
    STATUS_PROCESSING: 'Processando',
    STATUS_COMPLETED: 'Concluído',
    STATUS_PARTIALLY_UPDATED: 'Parcialmente atualizados',
    STATUS_PARTIALLY_UPDATED_ROWS: 'Linhas não carregadas',
    FILE_UNAVAILABLE: 'Arquivo indisponível'
  },
  AlertCountryChangeView: {
    TITLE: 'Tem certeza de que deseja acessar um país diferente?',
    ALERT_MESSAGE_1: 'Se você abrir o link de ',
    ALERT_MESSAGE_2:
      ', você terá acesso aos produtos deste país e perderá os filtros aplicados anteriormente.',
    CANCEL_BUTTON: 'Cancelar',
    OPEN_BUTTON: 'Sim, acessar'
  },
  VendorZoneSelector: {
    INPUT_LABEL: 'Fornecedor'
  },
  CsvTableView: {
    UPLOAD_TITLE: 'Sem uploads',
    UPLOAD_DESCRIPTION: 'Seus arquivos carregados serão exibidos aqui.',
    DOWNLOAD_TITLE: 'Sem downloads',
    DOWNLOAD_DESCRIPTION: 'Seus arquivos para baixar serão exibidos aqui.'
  },
  DiscardProductModalActions: {
    SUBTITLE_TEXT_LABEL: 'Toda informação inserida será perdida.'
  },
  ProductConfirmationModalView: {
    CREATE_LABEL_BUTTON: 'Criar',
    CANCEL_LABEL_BUTTON: 'Cancelar',
    CREATE_MODAL_TITLE: 'Criar produto',
    DISCARD_LABEL_BUTTON: 'Descartar',
    DISCARD_MODAL_TITLE: 'Tem certeza que você quer excluir esse produto?'
  },
  UpdateProductImageUseCase: {
    UNABLE_TO_UPDATE_IMAGE_PRODUCT: 'Não foi possível atualizar a imagem deste produto',
    IMAGE_SUCCESSFULLY_UPDATE: 'Você atualizou com sucesso a imagem deste produto!'
  },
  PublishProductModalActions: {
    SUMMARY_TEXT_LABEL: 'Summary',
    SUBTITLE_TEXT_LABEL_CREATE: 'Você deseja criar esse produto?'
  },
  CreateProductFormView: {
    CREATE: 'Criar',
    DISCARD: 'Descartar',
    DETAILS: 'Detalhes',
    NEW_PRODUCT: 'Novo produto',
    REQUIRED_FIELD_MESSAGE: 'O preenchimento deste campo é obrigatório',
    INVALID_DATE_MESSAGE: 'Data inválida',
    INVALID_NUMBER_MESSAGE: 'O número precisa ser maior ou igual a zero'
  },
  FormikInputBoolean: {
    YES: 'Sim',
    NO: 'Não',
    ENABLED: 'Habilitado',
    DISABLED: 'Desabilitado'
  },
  ProductAttributesCardView: {
    ATTRIBUTES: 'Atributos'
  },
  BooleanAttributesValue: {
    TRUE: 'Sim',
    FALSE: 'Não'
  },
  FifoAttributesValue: {
    YELLOW: 'Amarelo',
    REGULAR: 'Regular',
    ORANGE: 'Laranja'
  },
  ZoneManagementView: {
    ZONE_SELECTOR_COUNTRY_LABEL: 'País:',
    ZONE_SELECTOR_VENDOR_LABEL: 'Vendedor:'
  },
  FormikTabWrapper: {
    PRODUCT_QUANTITY_INFORMATION: 'Esse produto possui {count} {attributeType}',
    LEGACY: 'Atributos legados',
    DYNAMIC: 'Atributos dinamicos',
    NO_RESULT: 'Esse produto não possui {attributeType}'
  },
  UpdateProductGeneralInformationUseCase: {
    SUCCESSFULLY_UPDATE_PRODUCT: 'Produto editado com sucesso',
    FAILED_UPDATE_PRODUCT:
      'A atualização do seu produto falhou devido a problemas técnicos. Por favor, tente novamente.'
  },
  UpdateProductAttributesUseCase: {
    PRODUCT_SUCCESSFULLY_EDITED: 'Produto editado com sucesso',
    FAILED_PRODUCT_ATTRIBUTE_UPDATE:
      'A atualização do seu produto falhou devido a problemas técnicos. Por favor, tente novamente.'
  },
  FilterChipListView: {
    PRODUCT_START_DATE_FILTER_CHIP: 'De',
    PRODUCT_END_DATE_FILTER_CHIP: 'Até',
    PRODUCT_START_AND_END_DATE_FILTER: 'a'
  },
  ProductDetailsTabView: {
    MESSAGE_HEADER: 'Enviar informações e atributos do produto.',
    PRODUCT_ALREADY_TO_BE_PUBLISHED: 'Este produto está pronto para ser publicado.'
  },
  Attributes: {
    VENDOR_NAME: 'Nome do vendedor',
    VENDOR_ID: 'ID do vendedor',
    SERVICE_MODEL: 'Modelo de negócio',
    NON_EDITABLE_TOOLTIP:
      'Este campo é essencial para o upload do CSV, portanto, não pode ser desmarcado.'
  },
  AutocompleteInputView: {
    NOT_FOUND: 'Vendedor não encontrado',
    SEARCH_BY: ' Pesquise por nome de exibição/comercial'
  },
  CsvGuidelinesModalView: {
    TITLE: 'Diretrizes de CSV',
    SUB_TITLE: 'Para ter um upload bem-sucedido, certifique-se de seguir estas regras:',
    MAX_PRODUCTS_GUIDE: 'Carregue no máximo 2.000 produtos por arquivo.',
    MANDATORY_COLUMNS_GUIDE: 'As colunas CSV ProductId e SKU são obrigatórias.',
    CSV_SEPARATOR_GUIDE: 'O separador CSV deve ser vírgula ( , ), não ponto e vírgula ( ; ).',
    DOUBLE_QUOTES_GUIDE: 'Cada dado da célula CSV deve estar entre aspas duplas ( “ “ ).',
    FULL_DOCUMENTATION: 'Leia a documentação completa',
    GOT_IT: 'Entendi'
  },
  UpdateBulkProductsUseCase: {
    UPDATE_STARTED:
      'Atualizando {totalProductsToUpdate} produto(s). Isso pode levar até um minuto.',
    SUCCESS_NOTIFICATION:
      'Você atualizou o {attributeName} de {totalSuccessProductsUpdated} produto(s) para {newValue}.',
    FAILED_NOTIFICATION:
      '{totalFailedProducts} produtos falharam ao atualizar seus {attributeName}',
    COPY_PRODUCTS: 'Copiar SKU(s)',
    MANUFACTURER: 'Fabricante',
    STATUS: 'Status'
  },
  ProductTableSidebarView: {
    LOADING: 'Carregando...',
    RESULTS: 'Mostrando {totalProducts} resultados',
    CLEAR: 'Limpar',
    APPLY: 'Aplicar'
  }
};

export default ptBR;
