import * as Grid from '@admin-portal-shared-components/grid';
import {
  createGenerateClassName,
  CssBaseline,
  StylesProvider,
  ThemeProvider
} from '@material-ui/core';
import {
  generateIncrementalCounter,
  useAnalyticsService,
  useFeatureToggle,
  useFeatureToggleService
} from 'admin-portal-shared-services';
import React from 'react';
import ReactDOM from 'react-dom';
import { PIM_SIDE_MENU } from './config/featureToggles';
import { EnvProvider } from './EnvProvider';
import { EnvConfig } from './EnvProvider/EnvProvider.types';
import { I18nLanguageProvider } from './i18n/LanguageProvider';
import { VendorProvider } from './providers/VendorProvider/VendorProvider';
import Router from './Router';
import theme from './themes';

const removeWhiteSpaceAndLineBreaks = (text: string): string => {
  return text.replace(/(\r\n|\n|\r|\s+)/gm, ' ');
};

interface EnvProps extends EnvConfig {
  PIM_SEGMENT_KEY: string;
  CMS_TOKEN: string;
}

const REPO_NAME = 'pim-products';

const counterGenerator = generateIncrementalCounter(REPO_NAME);

const generateClassName = createGenerateClassName({
  productionPrefix: REPO_NAME,
  seed: REPO_NAME,
  customGetNextCounterId: counterGenerator
});

const metaTagsSEO = (
  <>
    <meta name="Googlebot" content="noindex, nofollow, nosnippet" />
    <meta name="robots" content="nosnippet" />
    <meta
      name="description"
      content={removeWhiteSpaceAndLineBreaks(`A gateway to access all applications and data 
          regarding marketplace for 3rd party suppliers and internal users including but not limited to DDCs, 
          membership, and logistics amongst others.`)}
    />
  </>
);

function App({ PIM_SEGMENT_KEY, CMS_HOST, CMS_TOKEN }: EnvProps): JSX.Element {
  const headNode = document.head;
  const analyticsService = useAnalyticsService();
  useFeatureToggleService().loadToggles();
  analyticsService.load(PIM_SEGMENT_KEY);
  const pimSideMenuFT = useFeatureToggle(PIM_SIDE_MENU);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline>
        <StylesProvider generateClassName={generateClassName}>
          {ReactDOM.createPortal(metaTagsSEO, headNode)}
          <EnvProvider env={{ CMS_HOST, CMS_TOKEN }}>
            <I18nLanguageProvider>
              <VendorProvider>
                <Grid.Container
                  style={{
                    rowGap: '1rem'
                  }}
                  type="fluid"
                  sidebar={pimSideMenuFT}
                >
                  <Router />
                </Grid.Container>
              </VendorProvider>
            </I18nLanguageProvider>
          </EnvProvider>
        </StylesProvider>
      </CssBaseline>
    </ThemeProvider>
  );
}

export default App;
