import { usePreferredLanguageV2 } from 'admin-portal-shared-services';
import React from 'react';
import { PREFERRED_LANGUAGE } from '../config/constants';

export const i18nLanguageContext = React.createContext({
  language: '',
  setProviderLanguage: undefined
});

interface Props {
  children: React.ReactNode;
}

export const I18nLanguageProvider = ({ children }: Props): JSX.Element => {
  const { preferredLanguage, defaultLanguage } = usePreferredLanguageV2();
  const userPreferredLanguage = localStorage.getItem(PREFERRED_LANGUAGE) ?? preferredLanguage;
  const userLanguage = userPreferredLanguage ?? defaultLanguage;
  const [language, setProviderLanguage] = React.useState(userLanguage);

  return (
    <i18nLanguageContext.Provider value={{ language, setProviderLanguage }}>
      {children}
    </i18nLanguageContext.Provider>
  );
};
