import { hasLoadedOptimizely, isToggleEnabled } from '@/utils/toggle';
import { allCountries } from '@config/constants';
import {
  PIM_BE_ENABLED_GLOBAL,
  PIM_BO_ENABLED_DTC,
  PIM_BO_ENABLED_GLOBAL,
  PIM_EU_CLUSTER,
  PIM_NL_ENABLED_GLOBAL,
  PIM_PA_ENABLED_DTC,
  PIM_PH_ENABLED_GLOBAL
} from '@config/featureToggles';
import permissionConst from '@config/permissionConst';
import {
  useAuthenticationService,
  useClusterService,
  usePermissionService
} from 'admin-portal-shared-services';

interface ToggleControledCountries {
  [key: string]: boolean;
}

const europeCountries = ['AQ', 'GB', 'ES', 'DE', 'NL', 'BE', 'PH', 'ID'];
const europeCountriesRestricted: string[] = [];
const dtcEnvs = ['Global_DTC'];

export const buildActivationCountries = async ({
  isDtc
}: {
  isDtc: boolean;
}): Promise<ToggleControledCountries> => {
  await hasLoadedOptimizely();

  if (isDtc) {
    const pimPaEnabledGlobal = isToggleEnabled(PIM_PA_ENABLED_DTC);
    const pimBoEnabledGlobal = isToggleEnabled(PIM_BO_ENABLED_DTC);
    const dtcActivationCountries: ToggleControledCountries = {
      PA: pimPaEnabledGlobal,
      BO: pimBoEnabledGlobal
    };
    return dtcActivationCountries;
  }

  const pimBoEnabledGlobal = isToggleEnabled(PIM_BO_ENABLED_GLOBAL);
  const pimNlEnabledGlobal = isToggleEnabled(PIM_NL_ENABLED_GLOBAL);
  const pimBeEnabledGlobal = isToggleEnabled(PIM_BE_ENABLED_GLOBAL);
  const pimPhEnabledGlobal = isToggleEnabled(PIM_PH_ENABLED_GLOBAL);
  const globalActivationCountries: ToggleControledCountries = {
    BO: pimBoEnabledGlobal,
    NL: pimNlEnabledGlobal,
    PH: pimPhEnabledGlobal,
    BE: pimBeEnabledGlobal
  };

  return globalActivationCountries;
};

export const getDtcCountries = async (): Promise<string[]> => {
  const dtcCountries = [
    'HN',
    'SV',
    'PA',
    'BO',
    'PY',
    'PE',
    'EC',
    'CO',
    'DO',
    'AR',
    'MX',
    'ZA',
    'BR'
  ];
  return getActivatedCountries(dtcCountries, await buildActivationCountries({ isDtc: true }));
};

export const getUserCountries = async (): Promise<string[]> => {
  const isUserGlobal = usePermissionService().has(permissionConst.ALL_COUNTRIES);
  if (isUserGlobal) {
    if (isDTC()) return getDtcCountries();
    return getActivatedCountries(allCountries, await buildActivationCountries({ isDtc: false }));
  }
  return getActivatedCountries(
    useAuthenticationService().getSupportedCountries(),
    await buildActivationCountries({ isDtc: false })
  );
};

export const getActivatedCountries = (
  countries: Array<string>,
  countriesWithToggle: ToggleControledCountries
): Array<string> => {
  return countries.filter(country => {
    if (Object.prototype.hasOwnProperty.call(countriesWithToggle, country)) {
      return countriesWithToggle[country];
    }
    return true;
  });
};

export const hasMultiZoneAccess = (): boolean => {
  const isUserGlobal = usePermissionService().has(permissionConst.ALL_COUNTRIES);

  return isUserGlobal || useAuthenticationService().getSupportedCountries().length > 1;
};

export const isDTC = (): boolean => {
  return dtcEnvs.includes(useClusterService().getCluster());
};

export const isLocatedInEurope = (country: string): boolean => europeCountries.includes(country);
export const isRestrictedCountry = (country: string): boolean =>
  europeCountriesRestricted.includes(country);

export const getCluster = async (country: string = undefined): Promise<string> => {
  await hasLoadedOptimizely();

  const pimEUClusterFT = isToggleEnabled(PIM_EU_CLUSTER);
  const isEurope = isLocatedInEurope(country ?? localStorage.getItem('selectedCountry'));
  return isEurope && pimEUClusterFT ? '/eu' : '';
};

export const getSelectedCountry = (): string => localStorage.getItem('selectedCountry') ?? '';
