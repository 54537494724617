import MessageMap from '../domains/MessageMap';

const es419: MessageMap = {
  ProductDetailsRoute: {
    PAGE_LAYOUT_CAPTION: 'Productos',
    CURRENT_PAGE_TITLE: 'Detalles',
    FILL_FIELD: 'rellene el campo: {labels}',
    FILL_FIELDS: 'rellene los campos: {labels}',
    ALERT_ERROR_MESSAGE:
      'No hay ningún {languageAndfieldNamesWithError} registrado para este producto. Por favor, {fieldLabelsWithError}.',
    NAME: 'nombre',
    PRODUCT_TITLE: 'título del producto',
    STATUS: 'status',
    IMAGES: 'imagen',
    DESCRIPTION: 'descripción',
    PRODUCT_DESCRIPTION: 'descripción del producto',
    ATTRIBUTES: 'atributos',
    MANUFACTURER: 'fabricante',
    LANGUAGE_AND_FIELD: '{field} en {language}',
    AND: 'y'
  },
  UpdateProductStatusModal: {
    ENABLED_STATUS_LABEL: 'Conectado',
    DISABLED_STATUS_LABEL: 'Desconectado',
    SUBMIT_BUTTON_LABEL: 'GUARDAR',
    CANCEL_BUTTON_LABEL: 'CANCELAR'
  },
  ProductDetailsHeader: {
    CREATED_LABEL: 'Creado el',
    CLASS_LABEL: 'Clase',
    LAST_EDITED_LABEL: 'Última edición',
    LAST_EDITED_BY_LABEL: 'Última edición por'
  },
  EditGeneralInformationModal: {
    TITLE: 'Información del producto',
    SUBMIT_BUTTON_LABEL: 'GUARDAR',
    CANCEL_BUTTON_LABEL: 'CANCELAR',
    REQUIRED_VALIDATION_MESSAGE: 'Este campo es obligatorio'
  },
  EditButtonGeneralInformation: {
    EDIT_BUTTON: 'EDICIÓN'
  },
  EditButtonAttributeCardView: {
    EDIT_BUTTON: 'EDICIÓN'
  },
  ProductImageUploaderView: {
    EDIT_LABEL_BUTTON: 'Edición',
    UPLOAD_MAIN_PRODUCT_IMAGE: 'Subir la foto principal del producto.',
    THUMBNAILS_MUST_JPG_PNG_FORMAT:
      'La imagen deberá ser en formato JPG o PNG y pesar menos de 1MB.',
    COPY_BUTTON_TEXT: 'Copiar el URL de la imagen',
    SAVE_BUTTON: 'Guardar',
    CANCEL_BUTTON: 'Cancelar',
    COPY_URL_TOOLTIP: 'URL de la imagen copiado'
  },
  ImageCard: {
    TOOLTIP_ERROR_MESSAGE:
      'Dominio o dirección de imagen no válidos. Por favor, suba una nueva imagen.'
  },
  ImageCardView: {
    UPLOAD_PRODUCT_IMAGE: 'Subir la foto del producto.',
    THUMBNAILS_INFORMATION: 'La imagen deberá ser en formato JPG o PNG y pesar menos de 1MB.',
    COPY_URL_TOOLTIP: 'URL de la imagen copiado'
  },
  GeneralInformationCardView: {
    ENABLED_STATUS_LABEL: 'Conectado',
    DISABLED_STATUS_LABEL: 'Desconectado',
    NOT_FOUND: 'No encontrado.'
  },
  ProductInformationCardView: {
    PRODUCT_INFORMATION_CARD_TITLE: 'Información del producto',
    SUPPLIER_LABEL: 'Proveedor',
    CATEGORY_LABEL: 'Clase',
    SKU_INPUT_LABEL: 'SKU *',
    SKU_TOOLTIP_MESSAGE:
      'El SKU es un identificador único usado para distinguir productos en venta individualmente.',
    PRODUCT_TITLE_TOOLTIP_MESSAGE:
      'Este es el nombre que aparecerá en nuestro Marketplace. Por ejemplo: Nombre de la cerveza lata 355 ml',
    PRODUCT_TITLE_LABEL: 'Título del producto *',
    PRODUCT_DESCRIPTION_LABEL: 'Descripción del producto',
    PRODUCT_VARIATION_ALREADY_EXIST:
      ' Esta variación de producto ya existe en nuestra base de datos. Si se trata de una variación de producto diferente con los mismos valores de atributo, debe tener un título y un SKU diferentes.',
    THE_TITLE_ALREADY_BEING_USED: 'El título ya está en uso',
    THE_SKU_ALREADY_EXISTS: 'Este SKU ya existe',
    FIELD_STATUS_LABEL: 'Status'
  },
  EditGeneralInformationFieldset: {
    PRODUCT_TITLE_LABEL: 'Título del producto *',
    PRODUCT_DESCRIPTION_LABEL: 'Descripción del producto',
    CATEGORY_LABEL: 'Clase',
    MANUFACTURER: 'Fabricante *'
  },
  DeleteProductImageUseCase: {
    SUCCESS_NOTIFICATION: 'Imagen del producto eliminada.'
  },
  GeneralInformationCard: {
    PRODUCT_INFO: 'Información del producto',
    PRODUCT_TITLE: 'Título del producto *',
    PRODUCT_DESCRIPTION: 'Descripción del producto',
    MANUFACTURER: 'Fabricante *',
    STATUS: 'Status *'
  },
  AttributesCard: {
    CARD_TITLE: 'Atributos'
  },
  EditAttributesModalView: {
    TITLE: 'Atributos',
    SUBMIT_BUTTON_LABEL: 'Guardar',
    CANCEL_BUTTON_LABEL: 'Cancelar',
    REQUIRED_FIELD_MESSAGE: 'Este campo es obligatorio',
    INVALID_DATE_MESSAGE: 'Fecha invalida'
  },
  ProductHeaderTooltipMessages: {
    CREATED_AT: 'Fecha de creación en PIM',
    UPDATED_AT: 'Fecha en la que el producto fue editado por última vez.',
    UPDATED_BY: 'El usuario que editó el producto por última vez.'
  },
  UpdateBulkProductsManufacturerUseCase: {
    SUCCESS_NOTIFICATION: 'Fabricante de SKU actualizado',
    FAILED_NOTIFICATION: 'Error al actualizar SKU',
    ERROR_NOTIFICATION: 'No se pueden actualizar los productos',
    MAX_SELECTED_ERROR:
      'PIM admite la edición masiva de hasta 150 productos a la vez. Por favor, deseleccione algunos productos.'
  },
  AlertAttributeRequiredView: {
    CHECK_REQUIRED_FIELD_ABLE_MAKE_PRODUCT_ACTIVE:
      'Chequear los campos obligatorios para habilitar este producto.'
  },
  ProductListRoute: {
    PAGE_LAYOUT_CAPTION: 'Productos',
    PAGE_LAYOUT_TITLE: 'Productos'
  },
  AddProductButton: {
    ADD_PRODUCT_BUTTON_LABEL: '+ AGREGAR PRODUCTO',
    SELECT_VENDOR_TOOLTIP: 'Por favor, seleccione un vendedor antes de crear un producto'
  },
  AutoCompleteSearchView: {
    SEARCH_SKU_ONLY: 'Buscar por SKU',
    SEARCH_SKU_AND_TITLE: 'Busque escribiendo o pegando una lista de títulos de productos o SKU',
    CLEAR_ALL: 'Limpiar'
  },
  CreateProductModalView: {
    TITLE: 'Nuevo producto',
    LABEL_SUBMIT_BUTTON: 'Comenzar',
    SERVICE_MODEL: 'Modelo de servicio',
    SUB_TITLE_HEADER: 'Selecciona el tipo de modelo de servicio que quieres crear.',
    SELECT_CLASS: 'Seleccionar clase',
    SELECT_CLASS_DESCRIPTION:
      'Selecciona una clase de la lista a continuación. Definirá los atributos de tu producto.',
    CLASS: 'Clase',
    LABEL_CANCEL_BUTTON: 'Cancelar',
    LABEL_BACK_BUTTON: 'Regresar',
    NEXT: 'Siguiente',
    SELECTED_TEXT: 'Seleccionado',
    SELECTED_CLASS: 'Seleccionado: {name}',
    SUPPLIER_LABEL: 'Proveedor',
    SELECT_A_CLASS: 'Selecciona una clase',
    SELECT_CLASS_SUBTITLE:
      'Este nuevo producto heredará todos los campos de atributos de la Clase seleccionada.'
  },
  CategoryOptionsLoaderUseCase: {
    UNABLE_LOAD_CATEGORY_LIST: 'No se pueden cargar los detalles de la clase'
  },
  LoadCategoryChildren: {
    UNABLE_LOAD_CATEGORY_LIST: 'No se pueden cargar los detalles de la clase'
  },
  VendorSelector: {
    INPUT_LABEL: 'Seleccionar el proveedor',
    INPUT_PLACEHOLDER: 'Proveedor'
  },
  CategorySelector: {
    SECTION_TITLE: 'Seleccionar clase',
    SECTION_DESCRIPTION:
      'Selecciona una clase de la lista a continuación. Definirá los atributos de tu producto.',
    INPUT_LABEL: 'Seleccionar clase',
    INPUT_PLACEHOLDER: 'Clase'
  },
  DownloadCsvPlpView: {
    BUTTON_LABEL: 'DESCARGAR CSV',
    DOWNLOAD_ALL: 'DESCARGAR TODO',
    TOOLTIP_DOWNLOAD_ALL: 'Descargará un CSV con {totalNumber} producto(s)',
    TOOLTIP_MAX_PRODUCTS:
      'No puede descargar más de 2000 productos a la vez. Utilice los filtros y la barra de búsqueda para limitar su búsqueda.',
    DOWNLOADING_CSV_LABEL: 'Descargando'
  },
  ProductTable: {
    HEADER_COLUMN_TITLE: 'Título del producto',
    HEADER_COLUMN_SKUNUMBER: 'SKU',
    HEADER_COLUMN_BRAND: 'Marca',
    HEADER_COLUMN_VENDOR: 'Nombre del vendedor',
    HEADER_COLUMN_MODEL: 'Modelo de servicio',
    HEADER_COLUMN_CATEGORY: 'Clase',
    HEADER_COLUMN_STATUS: 'Status',
    HEADER_COLUMN_LASTUPDATED: 'Última edición',
    HEADER_COLUMN_CREATEDAT: 'Creado el',
    HEADER_COLUMN_COUNTRY: 'País',
    HEADER_COLUMN_MANUFACTURER: 'Fabricante',
    TOOLTIP_COLUMN_SKUNUMBER:
      'El SKU es un identificador único usado para distinguir productos en venta individualmente.',
    TOOLTIP_COLUMN_BRAND: 'El nombre de la marca del producto',
    TOOLTIP_COLUMN_VENDOR: 'El nombre del vendedor',
    TOOLTIP_COLUMN_MODEL: 'El nombre del modelo',
    TOOLTIP_COLUMN_CATEGORY: 'La clasificación del producto',
    TOOLTIP_COLUMN_LASTUPDATED: 'Fecha en la que el producto fue editado por última vez.',
    TOOLTIP_COLUMN_CREATEDAT: 'La fecha en la que el producto fue creado.',
    TOOLTIP_COLUMN_COUNTRY: 'País de origen del producto',
    TOOLTIP_COLUMN_MANUFACTURER: 'El nombre del fabricante del producto.',
    EMPTY_VENDOR_MESSAGE: 'El usuario no tiene ningún proveedor asociado',
    MISSING_INFORMATION: 'Información faltante'
  },
  ModalExportCsv: {
    DESCRIPTION: 'Descripción',
    IMAGE: 'URL de la imagen'
  },
  ModalExportCsvView: {
    CANCEL_BUTTON: 'CANCELAR',
    DOWNLOAD_BUTTON: 'DESCARGAR',
    MODAL_TITLE: 'Descargar',
    COUNT_PRODUCTS: 'productos en fila',
    SELECT_FIELDS: 'Seleccionar campos',
    EMPTY_PRODUCTS_ERROR: 'Debe tener al menos un producto en la cola.',
    MAX_PRODUCTS_ERROR: 'Se excedió la cantidad de más de 2000 productos.'
  },
  CheckboxAttributesView: {
    ALL_FIELDS: 'Todos los campos',
    NON_EDITABLE: 'No editable',
    LEGACY: ' (Legado)'
  },
  CountrySelector: {
    INPUT_LABEL: 'Seleccionar país'
  },
  ProductWizardModeSelectorView: {
    THREE_P_L_MODE_SUBTITLE: 'Vendido por ABI y enviado por un proveedor.',
    ONE_P_THREE_L_MODE_TITLE: '1P3L',
    ONE_P_THREE_L_MODE_SUBTITLE: 'Vendido por ABI y enviado por un proveedor.'
  },
  EditProductTableAction: {
    ALL_FIELDS_DISABLED_TOOLTIP:
      'Debe seleccionar al menos un producto para habilitar la edición masiva',
    ONE_FIELD_SELECTED_TOOLTIP:
      'Solo puede actualizar una información a la vez. Guarda o cancela los cambios para seguir editando tus productos',
    ENABLED: 'Conectado',
    DISABLED: 'Desconectado'
  },
  EditProductTableActionView: {
    CANCEL_BUTTON_LABEL: 'Cancelar',
    SAVE_BUTTON_LABEL: 'Guardar cambios',
    SELECT_PLACEHOLDER: 'Cambiar status',
    ENABLED: 'Conectado',
    DISABLED: 'Desconectado',
    HAS_SELECTED:
      'Solo puede actualizar una información a la vez. Guarda o cancela los cambios para seguir editando tus productos.',
    CHANGE_MANUFACTURER: 'Cambiar fabricante',
    PRODUCT_COUNTER: '{totalProductsSelected} producto(s) seleccionado(s)'
  },
  Filters: {
    APPLY_BUTTON_LABEL: 'APLICAR',
    CANCEL_BUTTON_LABEL: 'CANCELAR'
  },
  FilterChipList: {
    PRODUCT_TITLE_FILTER: 'Título del producto',
    PRODUCT_STATUS_FILTER: 'Status',
    PRODUCT_STATUS_FILTER_ENABLED: 'Conectado',
    PRODUCT_STATUS_FILTER_DISABLED: 'Desconectado',
    PRODUCT_CREATED_DATE_FILTER: 'Creado el',
    PRODUCT_START_DATE_FILTER: 'Fecha de inicio',
    PRODUCT_END_DATE_FILTER: 'Fecha de finalización',
    WITH_PROBLEMS: 'Elementos con error'
  },
  FiltersModal: {
    APPLY_BUTTON: 'Aplicar',
    CANCEL_BUTTON: 'Cancelar'
  },
  ProductFilters: {
    FILTER: 'Filtros',
    FILTER_BY_PRODUCT_TITLE: 'Buscar el título del producto',
    PRODUCT_TITLE_FILTER: 'Título del producto',
    PRODUCT_STATUS_FILTER: 'Status',
    PRODUCT_STATUS_FILTER_ENABLED: 'Conectado',
    PRODUCT_STATUS_FILTER_DISABLED: 'Desconectado',
    PRODUCT_HAS_ERROR_FILTER: 'Elementos con error',
    PRODUCT_HAS_ERROR_FILTER_ENABLED: 'Si',
    PRODUCT_HAS_ERROR_FILTER_DISABLED: 'No',
    PRODUCT_CREATED_DATE_FILTER: 'Creado en',
    PRODUCT_START_DATE_FILTER: 'Fecha de inicio',
    PRODUCT_END_DATE_FILTER: 'Fecha de finalización',
    PRODUCT_COUNTRY_FILTER: 'País',
    PRODUCT_MANUFACTURER_FILTER: 'Fabricante',
    PRODUCT_MANUFACTURER_FILTER_SEARCH: 'Buscar',
    PRODUCT_HAS_IMAGE_FILTER: 'Imagen del producto',
    PRODUCT_HAS_IMAGE_FILTER_ENABLED: 'Con imagen',
    PRODUCT_HAS_IMAGE_FILTER_DISABLED: 'Sin imágen'
  },
  ProductManufacturerFilter: {
    EMPTY_MANUFACTURER: 'Inserte algo primero para ver los resultados.'
  },
  ProductCreatedDateFilterView: {
    INVALID_DATE_ERROR: 'Fecha inválida',
    MAX_START_DATE_ERROR: 'La fecha de inicio no podrá ser posterior a la fecha de hoy',
    MAX_START_DATE_ERROR_WITH_END_DATE:
      'La fecha de inicio no podrá ser posterior a la fecha de finalización',
    MIN_END_DATE_ERROR_WITH_START_DATE:
      'Fecha de finalización no puede ser antes de la fecha de inicio.',
    MIN_END_DATE_ERROR: 'Fecha de finalización no puede ser antes de 01/01/1899.',
    MIN_START_DATE_ERROR: 'La fecha de inicio no puede ser antes de 01/01/1899.',
    MAX_END_DATE_ERROR: 'Fecha de finalización no podrá ser posterior a la fecha de hoy.',
    START_DATE: 'Fecha de inicio',
    END_DATE: 'Fecha de finalización'
  },
  ProductTablePagination: {
    ROWS_PER_PAGE_LABEL: 'hileras por página'
  },
  SearchProductButton: {
    SEARCH: 'Buscar'
  },
  StatusTableCellView: {
    ENABLED: 'Conectado',
    DISABLED: 'Desconectado'
  },
  SkuTableCell: {
    TOOLTIP_COPY_TEXT: 'Copiado!'
  },
  UploadCsvModalView: {
    TITLE: 'Subir CSV',
    DRAG_AND_DROP_MESSAGE: 'Arrastra y suelta el archivo CSV aquí o',
    SELECT_FILE_BUTTON: 'Seleccionar archivo CSV',
    DESCRIPTION: 'Subir descripción',
    BUTTON_CANCEL: 'Cancelar',
    BUTTON_UPLOAD: 'Subir CSV',
    ONLY_CSV_SUPPORT: 'Solo admitimos archivos CSV.',
    COUNTRY_SELECTOR_COUNTRY: 'País',
    MODAL_TEXT:
      'Seleccione un archivo CSV que desee cargar y envíe varias actualizaciones de productos a la vez. Asegúrate de seguir las ',
    UPLOAD_GUIDELINES: 'pautas de carga',
    DESCRIPTION_PLACEHOLDER: 'Insertar texto aquí...'
  },
  BulkOperationsPage: {
    PAGE_TITLE: 'Editor de volumen',
    DOWNLOADS_TAB: 'Descargados',
    UPLOADS_TAB: 'Subidos',
    HISTORY_CARD: 'Historia'
  },
  UploadsTabView: {
    BUTTON: 'Subir Archivo',
    TITLE: 'Historia'
  },
  BulkDownloadTable: {
    HEADER_COLUMN_DATE: 'Fecha',
    HEADER_COLUMN_STATUS: 'Status',
    HEADER_COLUMN_DESCRIPTION: 'Descripción',
    HEADER_COLUMN_UPLOADDESCRIPTION: 'Cargar la descripción',
    HEADER_COLUMN_ERRORDESCRIPTION: 'Descripción del error',
    HEADER_COLUMN_COUNTRY: 'País',
    HEADER_COLUMN_DOWNLOAD: 'Acción',
    HEADER_COLUMN_TOTALROWS: 'Total de filas',
    HEADER_COLUMN_FAILEDROWS: 'Filas falladas',
    STATUS_FAILED_IMPORT_UPLOAD: 'Falló',
    STATUS_FAILED_ROWS: 'Filas falladas',
    STATUS_PARTIALLY_UPDATED_ROWS: 'Filas falladas',
    STATUS_PARTIALLY_UPDATED: 'Parcialmente actualizado',
    STATUS_COMPLETED: 'Terminado',
    STATUS_FAILED: 'Falló',
    STATUS_DONE: 'Terminado',
    STATUS_PROCESSING: 'Procesando',
    FILE_UNAVAILABLE: 'Archivo no disponible'
  },
  VendorZoneSelector: {
    INPUT_LABEL: 'Vendedor'
  },
  AlertCountryChangeView: {
    TITLE: '¿Estás seguro de que quieres abrir otro país?',
    ALERT_MESSAGE_1: 'Si abres el link de ',
    ALERT_MESSAGE_2:
      ', comenzarás a ver productos de ese país y perderás los filtros previamente utilizados.',
    CANCEL_BUTTON: 'Cancelar',
    OPEN_BUTTON: 'Sí, abrir'
  },
  CsvTableView: {
    UPLOAD_TITLE: 'No hay cargas',
    UPLOAD_DESCRIPTION: 'Tus archivos cargados se mostrarán aquí.',
    DOWNLOAD_TITLE: 'No hay descargas',
    DOWNLOAD_DESCRIPTION: 'Tus archivos descargados se mostrarán aquí.'
  },
  DiscardProductModalActions: {
    SUBTITLE_TEXT_LABEL: 'Toda la información adicionada se perderá.'
  },
  ProductConfirmationModalView: {
    CREATE_LABEL_BUTTON: 'Crear',
    CANCEL_LABEL_BUTTON: 'Cancelar',
    CREATE_MODAL_TITLE: 'Crear producto',
    DISCARD_LABEL_BUTTON: 'Descartar',
    DISCARD_MODAL_TITLE: '¿Estás seguro/a de querer descartar este producto?'
  },
  UpdateProductImageUseCase: {
    UNABLE_TO_UPDATE_IMAGE_PRODUCT: 'No se puede actualizar el producto de imagen',
    IMAGE_SUCCESSFULLY_UPDATE: '¡Has actualizado correctamente la imagen de este producto!'
  },
  PublishProductModalActions: {
    SUMMARY_TEXT_LABEL: 'Summary',
    SUBTITLE_TEXT_LABEL_CREATE: '¿Quieres crear este producto?'
  },
  CreateProductFormView: {
    CREATE: 'Crear',
    DISCARD: 'Descartar',
    DETAILS: 'Detalles',
    NEW_PRODUCT: 'Nuevo producto',
    REQUIRED_FIELD_MESSAGE: 'Este campo es obligatorio',
    INVALID_DATE_MESSAGE: 'Fecha invalida',
    INVALID_NUMBER_MESSAGE: 'El número debe ser mayor o igual a cero'
  },
  FormikInputBoolean: {
    YES: 'Sí',
    NO: 'No',
    ENABLED: 'Conectado',
    DISABLED: 'Desconectado'
  },
  ProductAttributesCardView: {
    ATTRIBUTES: 'Atributos'
  },
  BooleanAttributesValue: {
    TRUE: 'Sí',
    FALSE: 'No'
  },
  FifoAttributesValue: {
    YELLOW: 'Amarillo',
    REGULAR: 'Regular',
    ORANGE: 'Naranja'
  },
  ZoneManagementView: {
    ZONE_SELECTOR_COUNTRY_LABEL: 'País:',
    ZONE_SELECTOR_VENDOR_LABEL: 'Vendedor:'
  },
  FormikTabWrapper: {
    PRODUCT_QUANTITY_INFORMATION: 'Este producto tiene {count} {attributeType}',
    LEGACY: 'Atributos heredados',
    DYNAMIC: 'Atributos dinámicos',
    NO_RESULT: 'Este producto no tiene {attributeType}'
  },
  UpdateProductGeneralInformationUseCase: {
    SUCCESSFULLY_UPDATE_PRODUCT: 'Producto editado con éxito',
    FAILED_UPDATE_PRODUCT:
      'La actualización de su producto ha fallado debido a problemas técnicos. Inténtalo de nuevo.'
  },
  UpdateProductAttributesUseCase: {
    PRODUCT_SUCCESSFULLY_EDITED: 'Producto editado con éxito',
    FAILED_PRODUCT_ATTRIBUTE_UPDATE:
      'La actualización de su producto ha fallado debido a problemas técnicos. Inténtalo de nuevo.'
  },
  FilterChipListView: {
    PRODUCT_START_DATE_FILTER_CHIP: 'Del',
    PRODUCT_END_DATE_FILTER_CHIP: 'Hasta el',
    PRODUCT_START_AND_END_DATE_FILTER: 'al'
  },
  ProductDetailsTabView: {
    MESSAGE_HEADER: 'Enviar información y atributos del producto.',
    PRODUCT_ALREADY_TO_BE_PUBLISHED: 'Este producto está listo para ser publicado.'
  },
  Attributes: {
    VENDOR_NAME: 'Nombre del vendedor',
    VENDOR_ID: 'Identificación del vendedor',
    SERVICE_MODEL: 'Modelo de servicio',
    NON_EDITABLE_TOOLTIP:
      'Este campo es esencial para la carga de CSV, por lo que no se puede deseleccionar.'
  },
  AutocompleteInputView: {
    NOT_FOUND: 'Vendedor no encontrado',
    SEARCH_BY: 'Buscar por nombre comercial/de visualización'
  },
  CsvGuidelinesModalView: {
    TITLE: 'Directrices CSV',
    SUB_TITLE: 'Para tener una carga exitosa, asegúrese de seguir estas reglas:',
    MAX_PRODUCTS_GUIDE: 'Sube un máximo de 2000 productos por archivo.',
    MANDATORY_COLUMNS_GUIDE: 'Las columnas de CSV ProductId y SKU son obligatorias.',
    CSV_SEPARATOR_GUIDE: 'El separador CSV debe ser coma ( , ), no punto y coma ( ; ).',
    DOUBLE_QUOTES_GUIDE:
      'Los datos de cada celda CSV deben incluirse entre comillas dobles (" " ).',
    FULL_DOCUMENTATION: 'Lea la documentación completa',
    GOT_IT: 'Entendido'
  },
  UpdateBulkProductsUseCase: {
    UPDATE_STARTED:
      'Actualizando {totalProductsToUpdate} producto(s). Esto puede tardar hasta un minuto.',
    SUCCESS_NOTIFICATION:
      'Actualizaste el {attributeName} de {totalSuccessProductsUpdated} producto(s) a {newValue}.',
    FAILED_NOTIFICATION:
      '{totalFailedProducts} productos no pudieron actualizar su {attributeName}.',
    MANUFACTURER: 'Fabricante',
    STATUS: 'Status',
    COPY_PRODUCTS: 'Copiar SKU(s)'
  },
  ProductTableSidebarView: {
    LOADING: 'Cargando...',
    RESULTS: 'Mostrando {totalProducts} resultados',
    CLEAR: 'Limpiar',
    APPLY: 'Aplicar'
  }
};

export default es419;
