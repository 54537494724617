import { createEvent } from 'effector';
import PaginationInfo from '../../domains/PaginationInfo';
import Product from '../../domains/Product';
import SearchProduct from '../../domains/SearchProduct';

export const queryProducts = createEvent<null>('queryProducts');

export const queryProductsDone = createEvent<{
  pagination: PaginationInfo;
  products: Array<Product | SearchProduct>;
}>('queryProductsDone');

export const queryProductsFail = createEvent<Error>('queryProductsFail');

export const setLastSearchedProductUrl = createEvent<string>('setLastSearchedProductUrl');

export const setImageProduct = createEvent<{
  productId: string;
  imageUrl: string;
}>('setImageProduct');

export const setStatusProducts = createEvent<{
  productsUpdated: Array<string>;
  enabled: boolean;
}>('setStatusProducts');

export const setManufacturerProducts = createEvent<{
  productsUpdated: Array<string>;
  manufacturerId: string;
}>('setManufacturerProducts');

export const queryProductsCounter = createEvent<{
  type: string;
  productsCount: number;
  isLoading?: boolean;
}>('queryProductsCounter');

export const setDownloadQueue = createEvent<{ isDownloading: boolean }>('setDownloadQueue');
export const setIsProcessing = createEvent<{ isProcessing: boolean }>('setIsProcessing');
export const setSelectedProducts = createEvent<{
  selectedProducts: Array<{ country: string; sku: string }>;
}>('setSelectedProducts');
export const setAllProductsChecked = createEvent<{
  [key: string]: boolean;
}>('setAllProductsChecked');
