import { useContext } from 'react';
import { updateIntlProvider } from '../../i18n/formatters';
import { i18nLanguageContext } from '../../i18n/LanguageProvider';

function useI18nProvider(): {
  currentLanguage: string;
  changeProviderLanguage: (providerLanguage: string) => void;
} {
  const { language: currentLanguage, setProviderLanguage } = useContext(i18nLanguageContext);

  const changeProviderLanguage = (providerLanguage: string) => {
    updateIntlProvider(providerLanguage);
    setProviderLanguage(providerLanguage);
  };

  return { currentLanguage, changeProviderLanguage };
}

export default useI18nProvider;
