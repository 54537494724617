export const PIM_PDP_MULTILANGUAGE = 'pim-pdp-multilanguage';
export const PIM_PRODUCT_IMAGE_CARD_REFACTOR = 'pim-product-image-card-refactor';
export const PIM_TRANSLATE_LABELS = 'pim-translate-labels';
export const PIM_PDP_MULTILANGUAGE_IMAGE = 'pim-pdp-multilanguage-image';
export const PIM_IDENTIFY_PDP_PROBLEMS = 'pim-identify-pdp-problems';
export const PIM_SINGLE_ZONE_MANAGEMENT = 'pim-single-zone-management';
export const PIM_1P3L_PARAMETER = 'pim-1p3l-parameter';
export const PIM_BULK_COUNTRY = 'pim-bulk-country';
export const PIM_ALLOW_ENABLE_WITH_PROBLEMS = 'pim-allow-enable-with-problems';
export const PIM_PDP_REDESIGN = 'pim-pdp-redesign';
export const PIM_PDP_TOOLTIP = 'pim-pdp-tooltip';
export const PIM_MULTI_VENDORS = 'pim-multi-vendors';
export const PIM_NEW_PRODUCT_SELECT_CLASS = 'pim-new-product-select-class';
export const PIM_PDP_DYNAMIC_ATTRIBUTES = 'pim-pdp-dynamic-attributes';
export const PIM_SIDE_MENU = 'pim-side-menu';
export const PIM_EU_CLUSTER = 'pim-eu-cluster';
export const PIM_BULK_EDITOR_CLUSTER = 'pim-bulk-editor-clusters';
export const PIM_SERVICE_MODEL = 'pim-service-model';
export const PIM_PLP_FILTER_ALL_VENDORS = 'pim-plp-filter-all-vendors';
export const PIM_BO_ENABLED_GLOBAL = 'pim-bo-enabled-global';
export const PIM_BO_ENABLED_DTC = 'pim-bo-enabled-dtc';
export const PIM_PA_ENABLED_DTC = 'pim-pa-enabled-dtc';
export const PIM_NL_ENABLED_GLOBAL = 'pim-nl-enabled-global';
export const PIM_BE_ENABLED_GLOBAL = 'pim-be-enabled-global';
export const PIM_ENABLE_BULK_MANUFACTURER_PLP = 'pim_enable_bulk_manufacturer_plp';
export const PIM_PH_ENABLED_GLOBAL = 'pim-ph-enabled-global';
export const PIM_SELECT_COUNTER = 'pim-select-counter';
export const PIM_HAS_IMAGE_FILTER = 'pim-has-image-filter';
