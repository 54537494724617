const requests = [
  {
    id: '61671adfbaf4023d2fbe8a86',
    userId: '7ebe0441-7f27-4e57-a23e-9203dffffc76',
    status: 'DONE',
    type: 'IMPORT',
    description: 'Description sent by the user',
    exportError: null,
    createdAt: '2021-09-20T21:06:52.691Z',
    failedRows: 0,
    changedRows: null,
    totalRows: Math.floor(Math.random() * (300 - 10 + 1)) + 10
  },
  {
    id: '61671adfbaf4023d2fbe8a86',
    userId: '7ebe0441-7f27-4e57-a23e-9203dffffc76',
    status: 'PARTIALLY_UPDATED',
    type: 'IMPORT',
    description: 'Description sent by the user',
    exportError: null,
    createdAt: '2021-09-20T21:06:52.691Z',
    failedRows: 2,
    changedRows: null,
    totalRows: Math.floor(Math.random() * (300 - 10 + 1)) + 10
  },
  {
    id: '61671adfbaf4023d2fbe8a86',
    userId: '7ebe0441-7f27-4e57-a23e-9203dffffc76',
    status: 'DONE',
    type: 'IMPORT',
    description: 'Description sent by the user',
    exportError: null,
    createdAt: '2021-09-20T21:06:52.691Z',
    failedRows: 0,
    changedRows: null,
    totalRows: Math.floor(Math.random() * (300 - 10 + 1)) + 10
  },
  {
    id: '61671adfbaf4023d2fbe8a86',
    userId: '7ebe0441-7f27-4e57-a23e-9203dffffc76',
    status: 'DONE',
    type: 'IMPORT',
    description: 'Description sent by the user',
    exportError: null,
    createdAt: '2021-09-20T21:06:52.691Z',
    failedRows: 0,
    changedRows: null,
    totalRows: Math.floor(Math.random() * (300 - 10 + 1)) + 10
  },
  {
    id: '61671adfbaf4023d2fbe8a86',
    userId: '7ebe0441-7f27-4e57-a23e-9203dffffc76',
    status: 'DONE',
    type: 'IMPORT',
    description: 'Description sent by the user',
    exportError: null,
    createdAt: '2021-09-20T21:06:52.691Z',
    failedRows: 0,
    changedRows: null,
    totalRows: Math.floor(Math.random() * (300 - 10 + 1)) + 10,
    country: 'BR'
  },
  {
    id: '614b913db278c20d5aeee838',
    userId: '7ebe0441-7f27-4e57-a23e-9203dffffc76',
    status: 'PROCESSING',
    type: 'IMPORT',
    description: 'Description sent by the user',
    exportError: null,
    createdAt: '2021-09-20T21:06:52.691Z',
    failedRows: 0,
    changedRows: null,
    totalRows: Math.floor(Math.random() * (300 - 10 + 1)) + 10
  },
  {
    id: '614b913db278c20d5aeee838',
    userId: '7ebe0441-7f27-4e57-a23e-9203dffffc76',
    status: 'FAILED',
    type: 'IMPORT',
    description: 'Description sent by the user',
    exportError: 'Failed Reason',
    createdAt: '2021-09-20T21:06:52.691Z',
    failedRows: 13,
    changedRows: null,
    totalRows: Math.floor(Math.random() * (300 - 10 + 1)) + 10
  },
  {
    id: '614b913db278c20d5aeee838',
    userId: '7ebe0441-7f27-4e57-a23e-9203dffffc76',
    status: 'FAILED_IMPORT_UPLOAD',
    type: 'IMPORT',
    description: 'Description sent by the user',
    exportError: 'Failed Reason',
    createdAt: '2021-09-20T21:06:52.691Z',
    failedRows: 0,
    changedRows: null,
    totalRows: Math.floor(Math.random() * (300 - 10 + 1)) + 10,
    country: 'BR'
  }
];

export default requests;
