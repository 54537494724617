import { useAuthenticationService } from 'admin-portal-shared-services';
import axios, { AxiosInstance } from 'axios';
import uuid from 'uuid';
import { getApiHost } from '../../services/host/HostService';

export default function restClient(baseUrl = '', host = null): AxiosInstance {
  const AUTHORIZATION = 'Authorization';
  const REQUEST_TRACE_ID = 'requestTraceId';
  const authenticationService = useAuthenticationService();

  const instance = axios.create({
    baseURL: (host && !window.Cypress ? host : getApiHost()) + baseUrl
  });
  instance.defaults.headers.common[AUTHORIZATION] = authenticationService.getAuthHeader();
  instance.defaults.headers.common[REQUEST_TRACE_ID] = uuid.v4();
  return instance;
}
