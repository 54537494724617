import { FormatDateOptions, MessageDescriptor } from 'react-intl';
import { PREFERRED_LANGUAGE } from '../config/constants';
import { getObjectByNested } from '../utils/getObjectByNested';
import provider, { DEFAULT_LANGUAGE, getLanguageObject } from './provider';

let intlProvider = provider(localStorage.getItem(PREFERRED_LANGUAGE) ?? DEFAULT_LANGUAGE);

export const updateIntlProvider = (language: string): void => {
  intlProvider = provider(language);
};

export const formatMessage = (
  message: MessageDescriptor,
  values?: Record<string, string | number | boolean>
): string => intlProvider.formatMessage(message, values);

export const formatDate = (date: number | Date, options?: FormatDateOptions): string =>
  intlProvider.formatDate(date, {
    timeZone: 'UTC',
    ...options
  });

export const isTranslationAvailable = (id: string): boolean =>
  getObjectByNested(id, getLanguageObject()) !== undefined;
