import { ALL_VENDORS } from '@/config/constants';
import { BusinessModel } from '@/domains/BusinessModel';
import RequestVendorCommand from '@/domains/command/RequestVendorCommand';
import { toastService } from '@/services/toast/toastService';
import { getAllVendors, loadVendorById } from '@/services/vendor/VendorService';
import sortVendorByName from '@/utils/sortVendorByName';
import { formatMessage } from '@i18n/formatters';
import { queryProductsFail } from '@stores/productList/ProductListEvents';
import { TypeToast, useUserMetadata } from 'admin-portal-shared-services';
import { useStore } from 'effector-react';
import React from 'react';
import permissionConst from '../../config/permissionConst';
import Vendor from '../../domains/Vendor';
import zoneManagementStore from '../../stores/zoneManagement/ZoneManagementStore';
import CheckIfUserHasOnePermission from '../../utils/CheckIfUserHasOnePermission';

type VendorContextProps = {
  selectedVendorId: string;
  vendors: Vendor[];
  totalVendorPages: number;
  vendorPagesLoaded: number;
  setVendorPagesLoaded: (vendorPages: number) => void;
  setSelectedVendorId: (vendorId: string) => void;
  setVendorsList: (vendorsList: Array<Vendor>) => void;
  isLoading: boolean;
};

type UnormalizedVendor = {
  id: string;
  displayName: string;
  serviceModel: BusinessModel;
  country: string;
};

const LOCAL_STORAGE_KEY = 'selectedVendorId';

const normalizeVendors = (vendors: UnormalizedVendor[]): Vendor[] =>
  vendors.map(
    (vendor): Vendor => ({
      id: vendor.id,
      name: vendor.displayName,
      businessModel: vendor.serviceModel,
      country: vendor.country
    })
  );

const savedVendorExists = (vendors: Vendor[]) => {
  const vendorId = localStorage.getItem(LOCAL_STORAGE_KEY) ?? null;
  if (vendorId) {
    return !!vendors.find(eachVendor => eachVendor.id === vendorId);
  }
  return false;
};

const getVendorId = (vendors: Vendor[]) => {
  const isLocalStorageVendorExists = savedVendorExists(vendors);
  if (isLocalStorageVendorExists) {
    return localStorage.getItem(LOCAL_STORAGE_KEY);
  }

  return vendors?.[0]?.id;
};

export const VendorContext = React.createContext<VendorContextProps>({
  selectedVendorId: null,
  vendors: [],
  totalVendorPages: 0,
  vendorPagesLoaded: 0,
  setVendorPagesLoaded: null,
  setSelectedVendorId: null,
  setVendorsList: null,
  isLoading: true
});

interface Props {
  children: React.ReactNode;
}

export const VendorProvider = ({ children }: Props): JSX.Element => {
  const hasVendorsAllPermission = CheckIfUserHasOnePermission([permissionConst.ALL_VENDORS]);
  const { selectedCountry, hasLoadedCountries } = useStore(zoneManagementStore);
  const [selectedVendorId, setSelectedVendorId] = React.useState<string>(null);
  const [vendors, setVendors] = React.useState<Vendor[]>([]);
  const [totalVendorPages, setTotalVendorPages] = React.useState<number>(0);
  const [vendorPagesLoaded, setVendorPagesLoaded] = React.useState<number>(0);
  const [isLoading, setIsLoading] = React.useState(true);
  const selectedCountryRef = React.useRef(selectedCountry);
  const metadata = useUserMetadata();

  const hasNoVendorMessage = () => {
    setIsLoading(false);

    queryProductsFail(
      new Error(
        formatMessage({
          id: 'ProductTable.EMPTY_VENDOR_MESSAGE'
        })
      )
    );
    toastService().notify({
      type: TypeToast.ERROR,
      message: formatMessage({
        id: 'ProductTable.EMPTY_VENDOR_MESSAGE'
      })
    });
  };

  React.useEffect(() => {
    if (!hasVendorsAllPermission) {
      if (!metadata.isLoading && metadata.data?.vendors?.length > 0) {
        setTotalVendorPages(1);
        setVendorPagesLoaded(1);
        let unormalizedVendors = metadata.data?.vendors as UnormalizedVendor[];
        unormalizedVendors = unormalizedVendors.filter(
          vendor => vendor.country === selectedCountry
        );
        const normalizedVendors = normalizeVendors(unormalizedVendors).sort(sortVendorByName);
        const vendorId = getVendorId(normalizedVendors);
        if (selectedVendorId !== vendorId) {
          setVendors(normalizedVendors);
          setSelectedVendorId(vendorId);
          setIsLoading(false);
        }
      } else if (!metadata.isLoading) {
        hasNoVendorMessage();
      }

      if (metadata.isLoading) {
        setIsLoading(true);
      }
      selectedCountryRef.current = selectedCountry;
    }
  }, [selectedCountry, metadata.data, metadata.isLoading, isLoading]);

  React.useEffect(() => {
    if (hasVendorsAllPermission && hasLoadedCountries) {
      setIsLoading(true);
      getAllVendors({
        country: selectedCountry,
        pagination: { currentPage: 0, pageSize: 50 }
      })
        .then(data => {
          const allVendorsByCountry = [...data.items].sort(sortVendorByName);
          if (allVendorsByCountry.length > 0) {
            setTotalVendorPages(data?.pagination?.totalPages);
            setVendorPagesLoaded(1);
          }
          const vendorId = getVendorId(allVendorsByCountry);
          if (selectedVendorId !== vendorId) {
            setVendors(allVendorsByCountry);
            setSelectedVendorId(vendorId);
            setIsLoading(false);
          }
          if (allVendorsByCountry.length === 0) hasNoVendorMessage();
        })
        .catch(() => {
          hasNoVendorMessage();
        });
    }
  }, [selectedCountry, hasLoadedCountries]);

  React.useEffect(() => {
    const findVendor = vendors.find(eachVendor => eachVendor.id === selectedVendorId);
    if (selectedVendorId && findVendor === undefined && selectedVendorId !== ALL_VENDORS) {
      loadVendorById({
        country: selectedCountry,
        vendorId: selectedVendorId,
        pagination: { currentPage: 0, pageSize: 1 }
      } as RequestVendorCommand).then(vendor => {
        const currentVendors = [...vendors, vendor].sort(sortVendorByName);
        setVendors(currentVendors);
      });
    }
  }, [selectedVendorId]);

  const onSetSelectedVendorId = (vendorId: string): void => {
    localStorage.setItem(LOCAL_STORAGE_KEY, vendorId);
    setSelectedVendorId(vendorId);
  };

  const onSetVendorsList = (vendorsList: Array<Vendor>): void => {
    setVendors(vendorsList);
  };

  return (
    <VendorContext.Provider
      value={{
        setSelectedVendorId: onSetSelectedVendorId,
        setVendorsList: onSetVendorsList,
        selectedVendorId,
        vendors,
        totalVendorPages,
        vendorPagesLoaded,
        setVendorPagesLoaded,
        isLoading
      }}
    >
      {children}
    </VendorContext.Provider>
  );
};
