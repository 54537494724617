import { useFeatureToggle, useUserMetadata } from 'admin-portal-shared-services';
import React, { lazy, Suspense } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import LoadingView from './components/loadingView/LoadingView';
import { PREFERRED_LANGUAGE } from './config/constants';
import { PIM_PDP_REDESIGN, PIM_SIDE_MENU } from './config/featureToggles';
import usei18nProvider from './hooks/usei18nProvider/usei18nProvider';
import useStyles from './Router.styles';
import SideBarWrapper from './SideBarWrapper';

const CreateProductRoute = lazy(() => import('./pages/createProduct/CreateProductRoute'));
const ProductListRoute = lazy(() => import('./pages/productList/ProductListRoute'));
const ProductDetailsRoute = lazy(() => import('./pages/productDetails/ProductDetailsRoute'));
const ProductDetailsRedesignRoute = lazy(() =>
  import('./pages/redesign/productDetails/ProductDetailsRoute')
);
const BulkOperationsRoute = lazy(() => import('./pages/bulkOperations/BulkOperationsRoute'));

const BASENAME = '/pim';

const Router = (): JSX.Element => {
  const { content, containerBody, loadingView } = useStyles();

  const pdpRedesignFT = useFeatureToggle(PIM_PDP_REDESIGN);
  const pimSideMenuFT = useFeatureToggle(PIM_SIDE_MENU);
  const { changeProviderLanguage } = usei18nProvider();
  const { data } = useUserMetadata();

  React.useEffect(() => {
    if (
      data?.profile?.preferredLanguage &&
      data?.profile?.preferredLanguage !== localStorage.getItem(PREFERRED_LANGUAGE)
    ) {
      localStorage.setItem(PREFERRED_LANGUAGE, data.profile.preferredLanguage);
      changeProviderLanguage(data?.profile?.preferredLanguage);
    }
  }, [data]);

  return (
    <BrowserRouter>
      <div className={containerBody}>
        <Suspense
          fallback={
            <div className={loadingView}>
              <LoadingView />
            </div>
          }
        >
          <div className={content}>
            {pimSideMenuFT && <SideBarWrapper language={data?.profile?.preferredLanguage} />}
            <Switch>
              <Route path={`${BASENAME}/plp`} component={ProductListRoute} />
              <Route path={`${BASENAME}/product/new`} component={CreateProductRoute} />
              <Route
                path={`${BASENAME}/product/:id`}
                component={pdpRedesignFT ? ProductDetailsRedesignRoute : ProductDetailsRoute}
              />
              <Route path={`${BASENAME}/bulk`} component={BulkOperationsRoute} />
            </Switch>
          </div>
        </Suspense>
      </div>
    </BrowserRouter>
  );
};
export default Router;
