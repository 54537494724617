/* eslint-disable @typescript-eslint/no-explicit-any */
export const getObjectByNested = (
  nestedString: string,
  object: Record<string, any>
): string | Record<string, any> => {
  let objectValue = object;
  for (const nestedValue of nestedString.split('.')) {
    if (objectValue[nestedValue] === undefined) {
      objectValue = undefined;
      break;
    }
    objectValue = objectValue[nestedValue];
  }

  return objectValue;
};
