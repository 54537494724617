import Vendor from '@/domains/Vendor';

const sortVendorByName = (vendorA: Vendor, vendorB: Vendor): number => {
  const vendorALowerCase = vendorA.name.toLowerCase();
  const vendorBLowerCase = vendorB.name.toLowerCase();

  if (vendorALowerCase < vendorBLowerCase) {
    return -1;
  }
  if (vendorALowerCase > vendorBLowerCase) {
    return 1;
  }

  return 0;
};

export default sortVendorByName;
