import MessageMap from '../domains/MessageMap';

const en: MessageMap = {
  LoadProductDetailsUseCase: {
    UNABLE_LOAD_PRODUCT_DETAILS: 'Unable to load product details'
  },
  GeneralInformationCard: {
    PRODUCT_INFO: 'Product information',
    PRODUCT_TITLE: 'Product title *',
    PRODUCT_DESCRIPTION: 'Product description',
    PIM_CATEGORY: 'PIM class',
    THIS_MANUFACTURER_PRODUCT: 'This is the manufacturer of the product',
    MANUFACTURER: 'Manufacturer *',
    STATUS: 'Status *'
  },
  ImageCard: {
    TOOLTIP_ERROR_MESSAGE: 'Invalid image domain or address. Please, upload a new image.'
  },
  ImageCardView: {
    UPLOAD_PRODUCT_IMAGE: 'Upload product image',
    THUMBNAILS_INFORMATION: 'Thumbnails must be JPG, PNG format and must be less than 1MB',
    COPY_URL_TOOLTIP: 'Picture URL copied!'
  },
  ProductDetailsHeader: {
    STATUS_LABEL: 'Status',
    SKU_LABEL: 'SKU',
    CLASS_LABEL: 'Class',
    CREATED_LABEL: 'Created on',
    LAST_EDITED_LABEL: 'Last Edited',
    LAST_EDITED_BY_LABEL: 'Last Edited by'
  },
  AttributesCard: {
    CARD_TITLE: 'Attributes'
  },
  ProductManufacturerFilter: {
    EMPTY_MANUFACTURER: 'Insert something first to see the results.'
  },
  ProductHeaderTooltipMessages: {
    STATUS:
      'Status on the product detail page defines whether a product is published on the web or mobile app.',
    SKU:
      'SKU (stock keeping unit) is the unique identifier used to distinguish individual sellable items.',
    CREATED_AT: 'Date created in PIM',
    UPDATED_AT: 'The date that the product was last edited.',
    UPDATED_BY: 'The user who last edited the product.'
  },
  ProductDetailsRoute: {
    PAGE_LAYOUT_CAPTION: 'Products',
    CURRENT_PAGE_TITLE: 'Details',
    FILL_FIELD: 'fill the field: {labels}',
    FILL_FIELDS: 'fill these fields: {labels}',
    ALERT_ERROR_MESSAGE:
      "There's no {languageAndfieldNamesWithError} registered for this product. Please, {fieldLabelsWithError}.",
    NAME: 'name',
    PRODUCT_TITLE: 'product title',
    STATUS: 'status',
    IMAGES: 'image',
    DESCRIPTION: 'description',
    PRODUCT_DESCRIPTION: 'product description',
    MANUFACTURER: 'manufacturer',
    LANGUAGE_AND_FIELD: '{language} {field}',
    AND: 'and'
  },
  AlertAttributeRequiredView: {
    CHECK_REQUIRED_FIELD_ABLE_MAKE_PRODUCT_ACTIVE:
      'Check the required fields to enable this product.'
  },
  LoadProductsUseCase: {
    UNABLE_LOAD_PRODUCTS_AND_PAGINATION_INFO: 'Unable to load products and pagination information'
  },
  ProductTablePagination: {
    ROWS_PER_PAGE_LABEL: 'Rows per page'
  },
  ProductTable: {
    HEADER_COLUMN_TITLE: 'Product Title',
    HEADER_COLUMN_SKUNUMBER: 'SKU',
    HEADER_COLUMN_BRAND: 'Brand',
    HEADER_COLUMN_VENDOR: 'Vendor Name',
    HEADER_COLUMN_MODEL: 'Service Model',
    HEADER_COLUMN_CATEGORY: 'Class',
    HEADER_COLUMN_STATUS: 'Status',
    HEADER_COLUMN_LASTUPDATED: 'Last edited',
    HEADER_COLUMN_CREATEDAT: 'Created On',
    HEADER_COLUMN_COUNTRY: 'Country',
    HEADER_COLUMN_MANUFACTURER: 'Manufacturer',
    TOOLTIP_COLUMN_SKUNUMBER:
      'SKU (stock keeping unit) is the unique identifier used to distinguish individual sellable items',
    TOOLTIP_COLUMN_BRAND: 'The name of the product brand',
    TOOLTIP_COLUMN_VENDOR: 'The name of the vendor',
    TOOLTIP_COLUMN_MODEL: 'The name of the model',
    TOOLTIP_COLUMN_CATEGORY: 'The classification of the product',
    TOOLTIP_COLUMN_LASTUPDATED: 'The date that the product was last edited.',
    TOOLTIP_COLUMN_CREATEDAT: 'The date that the product was created.',
    TOOLTIP_COLUMN_COUNTRY: 'The origin country of the product',
    TOOLTIP_COLUMN_MANUFACTURER: 'The name of the product manufacturer.',
    EMPTY_VENDOR_MESSAGE: 'User has no associated vendor',
    MISSING_INFORMATION: 'Missing information'
  },
  Filters: {
    APPLY_BUTTON_LABEL: 'APPLY',
    CANCEL_BUTTON_LABEL: 'CANCEL'
  },
  FilterChipList: {
    PRODUCT_TITLE_FILTER: 'Product Title',
    PRODUCT_STATUS_FILTER: 'Status',
    PRODUCT_STATUS_FILTER_ENABLED: 'Enabled',
    PRODUCT_STATUS_FILTER_DISABLED: 'Disabled',
    PRODUCT_CREATED_DATE_FILTER: 'Created on',
    PRODUCT_START_DATE_FILTER: 'Start Date',
    PRODUCT_END_DATE_FILTER: 'End Date',
    WITH_PROBLEMS: 'Items with errors'
  },
  FilterChipListView: {
    PRODUCT_START_DATE_FILTER_CHIP: 'From',
    PRODUCT_END_DATE_FILTER_CHIP: 'Until',
    PRODUCT_START_AND_END_DATE_FILTER: 'to'
  },
  FiltersModal: {
    APPLY_BUTTON: 'Apply',
    CANCEL_BUTTON: 'Cancel'
  },
  ProductFilters: {
    FILTER: 'Filters',
    FILTER_BY_PRODUCT_TITLE: 'Search the product title',
    PRODUCT_TITLE_FILTER: 'Product Title',
    PRODUCT_STATUS_FILTER: 'Status',
    PRODUCT_STATUS_FILTER_ENABLED: 'Enabled',
    PRODUCT_STATUS_FILTER_DISABLED: 'Disabled',
    PRODUCT_HAS_ERROR_FILTER: 'Items with errors',
    PRODUCT_HAS_ERROR_FILTER_ENABLED: 'Yes',
    PRODUCT_HAS_ERROR_FILTER_DISABLED: 'No',
    PRODUCT_CREATED_DATE_FILTER: 'Created date',
    PRODUCT_START_DATE_FILTER: 'Start date',
    PRODUCT_END_DATE_FILTER: 'End date',
    PRODUCT_COUNTRY_FILTER: 'Country',
    PRODUCT_MANUFACTURER_FILTER: 'Manufacturer',
    PRODUCT_MANUFACTURER_FILTER_SEARCH: 'Search',
    PRODUCT_HAS_IMAGE_FILTER: 'Product image',
    PRODUCT_HAS_IMAGE_FILTER_ENABLED: 'With image',
    PRODUCT_HAS_IMAGE_FILTER_DISABLED: 'No image'
  },
  ProductCreatedDateFilterView: {
    INVALID_DATE_ERROR: 'Invalid date',
    MAX_START_DATE_ERROR: 'Start date should not be after current date',
    MAX_START_DATE_ERROR_WITH_END_DATE: 'Start date should not be after end date',
    MIN_END_DATE_ERROR_WITH_START_DATE: 'End date should not be before start date',
    MIN_END_DATE_ERROR: 'End date should not be before 01/01/1899',
    MIN_START_DATE_ERROR: 'Start date should not be before 01/01/1899',
    MAX_END_DATE_ERROR: 'End date should not be after current date',
    START_DATE: 'Start date',
    END_DATE: 'End date'
  },
  ProductTableSearch: {
    SELECT_OPTIONS_PRODUCT_TITLE: 'Product Title',
    SELECT_OPTIONS_SKU_NUMBER: 'SKU Number',
    SEARCH_PLACEHOLDER: 'Search'
  },
  AddProductButton: {
    ADD_PRODUCT_BUTTON_LABEL: '+ ADD PRODUCT',
    SELECT_VENDOR_TOOLTIP: 'Please select a vendor before creating a product'
  },
  ProductListRoute: {
    PAGE_LAYOUT_CAPTION: 'Products',
    PAGE_LAYOUT_TITLE: 'Products'
  },
  ModalExportCsv: {
    DESCRIPTION: 'Description',
    IMAGE: 'Image url'
  },
  ProductDetailsTabView: {
    MESSAGE_HEADER: 'Submit product information and attributes.',
    PRODUCT_ALREADY_TO_BE_PUBLISHED: 'This product is ready to be published.'
  },
  CreateProductModalView: {
    TITLE: 'New product',
    LABEL_SUBMIT_BUTTON: 'Get Started',
    SERVICE_MODEL: 'Service Model',
    TITLE_HEADER: 'Define type',
    SUB_TITLE_HEADER: 'Select the type of service model you want to create.',
    SELECT_CLASS: 'Select class',
    SELECT_CLASS_DESCRIPTION:
      'Select a class from the list below. It will define the attributes of your product.',
    CLASS: 'Class',
    LABEL_CANCEL_BUTTON: 'Cancel',
    NEXT: 'Next',
    LABEL_BACK_BUTTON: 'Go Back',
    SELECTED_TEXT: 'Selected',
    SELECTED_CLASS: 'Selected: {name}',
    SUPPLIER_LABEL: 'Supplier',
    SELECT_A_CLASS: 'Select a class',
    SELECT_CLASS_SUBTITLE:
      'This new product will inherit all the attribute fields of the Class selected.'
  },
  CategoryOptionsLoaderUseCase: {
    UNABLE_LOAD_CATEGORY_LIST: 'Unable to load class details'
  },
  LoadCategoryChildren: {
    UNABLE_LOAD_CATEGORY_LIST: 'Unable to load class details'
  },
  ProductWizardModeSelectorView: {
    ABI_MODE_SUBTITLE: 'Sold and delivered by BEES',
    ONE_P_MODE_TITLE: '1P',
    ONE_P_MODE_SUBTITLE: 'Sold and delivered by BEES',
    THREE_P_MODE_TITLE: '3P',
    THREE_P_MODE_SUBTITLE: 'Sold and delivered by Supplier',
    VARIANT_MODE_TITLE: 'New variation',
    VARIANT_MODE_SUBTITLE: 'Create product variations',
    THREE_P_L_MODE_TITLE: '3PL',
    THREE_P_L_MODE_SUBTITLE: 'Sold by ABI and Delivered by supplier',
    ONE_P_THREE_L_MODE_TITLE: '1P3L',
    ONE_P_THREE_L_MODE_SUBTITLE: 'Sold by ABI and Delivered by supplier'
  },
  CreateProductFormView: {
    CREATE: 'Create',
    DISCARD: 'Discard',
    VARIATIONS: 'Variations',
    DETAILS: 'Details',
    NEW_PRODUCT: 'New product',
    REQUIRED_FIELD_MESSAGE: 'This field is required',
    INVALID_DATE_MESSAGE: 'Invalid date',
    INVALID_NUMBER_MESSAGE: 'The number must be greater than or equal to zero'
  },
  CategorySelector: {
    SECTION_TITLE: 'Select class',
    SECTION_DESCRIPTION:
      'Choose a class from the list below. It will define the attributes of your product.',
    INPUT_LABEL: 'Select class',
    INPUT_PLACEHOLDER: 'Class'
  },
  VendorSelector: {
    INPUT_LABEL: 'Select Supplier',
    INPUT_PLACEHOLDER: 'Supplier',
    ALL_VENDORS: 'All Vendors',
    ALL_VENDORS_TOOLTIP: 'This will group all vendors you have access to.'
  },
  InitCreateProductFormUseCase: {
    UNABLE_LOAD_CATEGORY_DETAILS: 'Unable to load class details'
  },
  GeneralInformationCardView: {
    DISABLED_STATUS_LABEL: 'Disabled',
    ENABLED_STATUS_LABEL: 'Enabled',
    NOT_FOUND: 'Not found.'
  },
  ProductInformationCardView: {
    PRODUCT_INFORMATION_CARD_TITLE: 'Product information',
    SUPPLIER_LABEL: 'Supplier',
    CATEGORY_LABEL: 'Class',
    SKU_INPUT_LABEL: 'SKU *',
    SKU_TOOLTIP_MESSAGE:
      'SKU (stock keeping unit) is the unique identifier used to distinguish individual sellable items.',
    PRODUCT_TITLE_TOOLTIP_MESSAGE:
      'This is the name that will appear in our Marketplace. For example: Beer name 355 ml can',
    PRODUCT_TITLE_LABEL: 'Product title *',
    PRODUCT_DESCRIPTION_LABEL: 'Product description',
    PRODUCT_VARIATION_ALREADY_EXIST:
      'This product variation already exists in our database. If this is a different product variation with the same attribute values, it must have a different title and SKU.',
    THE_TITLE_ALREADY_BEING_USED: 'The title is already being used',
    THE_SKU_ALREADY_EXISTS: 'This SKU already exists',
    FIELD_STATUS_LABEL: 'Status'
  },
  ProductAttributesCardView: {
    ATTRIBUTES: 'Attributes'
  },
  CreateProductUseCase: {
    SUCCESSFULLY_CREATED_PRODUCT: 'New product created',
    UNABLE_TO_CREATE_PRODUCT: 'Unable to create product'
  },
  UpdateProductGeneralInformationUseCase: {
    SUCCESSFULLY_UPDATE_PRODUCT: 'Product successfully edited',
    FAILED_UPDATE_PRODUCT:
      'Your Product update has failed due to technical issues. Please, try again.'
  },
  EditProductModalActions: {
    SUBTITLE_TEXT_LABEL: 'The product variation below already exists in your zone.',
    SUMMARY_TEXT_LABEL: 'Summary',
    FOOTER_TEXT_LABEL: 'Change the title and SKU if this is a different product.'
  },
  PublishProductModalActions: {
    SUBTITLE_TEXT_LABEL:
      'Do you want to publish your product? Remember you can always come back and add variations to this product.',
    SUMMARY_TEXT_LABEL: 'Summary',
    SUBTITLE_TEXT_LABEL_CREATE: 'Do you want to create this product?'
  },
  DiscardProductModalActions: {
    SUBTITLE_TEXT_LABEL: 'All the information added will be lost.'
  },
  ProductConfirmationModalView: {
    PUBLISH_LABEL_BUTTON: 'Publish',
    CREATE_LABEL_BUTTON: 'Create',
    CANCEL_LABEL_BUTTON: 'Cancel',
    EDIT_LABEL_BUTTON: 'Edit',
    DISCARD_LABEL_BUTTON: 'Discard',
    EDIT_MODAL_TITLE: 'Product already exists',
    PUBLISH_MODAL_TITLE: 'Publish product',
    CREATE_MODAL_TITLE: 'Create product',
    DISCARD_MODAL_TITLE: 'Are you sure you want to discard this product?'
  },
  UpdateProductImageUseCase: {
    UNABLE_TO_UPDATE_IMAGE_PRODUCT: 'Unable to update image product',
    IMAGE_SUCCESSFULLY_UPDATE: 'You have successfully updated this product image!'
  },
  ProductImageUploaderView: {
    THUMBNAILS_MUST_JPG_PNG_FORMAT: 'Thumbnails must be JPG, PNG format and less than 1MB.',
    IMAGE_FORMAT_NOT_SUPPORTED:
      'Image format not supported. Please, upload an image in .png or .jpeg formats only',
    IMAGE_SIZE_NOT_SUPPORTED:
      'Image size limit exceeded. Please, upload an image no larger than 1MB.',
    EDIT_LABEL_BUTTON: 'Edit',
    UPLOAD_MAIN_PRODUCT_IMAGE: 'Upload main product image',
    ZOOM_MODAL_TITLE: 'Product images',
    COPY_BUTTON_TEXT: 'Copy Picture URL',
    SAVE_BUTTON: 'Save',
    CANCEL_BUTTON: 'Cancel',
    COPY_URL_TOOLTIP: 'Picture URL copied!'
  },
  EditButtonGeneralInformation: {
    EDIT_BUTTON: 'EDIT'
  },
  EditGeneralInformationFieldset: {
    PRODUCT_TITLE_LABEL: 'Product title *',
    PRODUCT_TITLE_TOOLTIP_MESSAGE:
      'This is the name that will appear in our Marketplace. For example: Beer name 355 ml can',
    PRODUCT_DESCRIPTION_LABEL: 'Product description',
    CATEGORY_LABEL: 'Class',
    SKU_LABEL: 'SKU',
    SKU_TOOLTIP_MESSAGE:
      'SKU (stock keeping unit) is the unique identifier used to distinguish individual sellable items.',
    THE_TITLE_ALREADY_BEING_USED: 'This title is already being used',
    MANUFACTURER: 'Manufacturer *',
    MANUFACTURER_TOOLTIP_MESSAGE: 'This is the manufacturer of the product.'
  },
  EditGeneralInformationModal: {
    TITLE: 'Product Information',
    SUBMIT_BUTTON_LABEL: 'SAVE',
    CANCEL_BUTTON_LABEL: 'CANCEL',
    REQUIRED_VALIDATION_MESSAGE: 'This field is required',
    REQUIRED_PRODUCT_TITLE_MESSAGE:
      'If product title is left blank default language version will be used',
    ERROR_MESSAGE_TITLE: 'Something went wrong',
    ERROR_RESPONSE_DESCRIPTION: "Sorry, we're having some technical issues. Please, try again.",
    ITEMS_VALIDATION_ERROR_RESPONSE_DESCRIPTION:
      "Sorry, we're having some technical issues. Please, contact the Support team sending this error number: 41",
    CLOSE_BUTTON: 'CLOSE',
    GOBACK_BUTTON: 'GO BACK'
  },
  FormikInputBoolean: {
    YES: 'Yes',
    NO: 'No',
    ENABLED: 'Enabled',
    DISABLED: 'Disabled'
  },
  UpdateProductAttributesUseCase: {
    PRODUCT_SUCCESSFULLY_EDITED: 'Product successfully edited',
    FAILED_PRODUCT_ATTRIBUTE_UPDATE:
      'Your Product update has failed due to technical issues. Please, try again.'
  },
  UpdateProductStatusUseCase: {
    SUCCESSFULLY_UPDATED_STATUS: 'Product status updated',
    UNABLE_TO_UPDATE_STATUS: 'Unable to update product status'
  },
  BooleanAttributesValue: {
    TRUE: 'Yes',
    FALSE: 'No'
  },
  FifoAttributesValue: {
    YELLOW: 'Yellow',
    REGULAR: 'Regular',
    ORANGE: 'Orange'
  },
  EditButtonAttributeCardView: {
    EDIT_BUTTON: 'EDIT'
  },
  UpdateProductStatusModal: {
    ENABLED_STATUS_LABEL: 'Enabled',
    DISABLED_STATUS_LABEL: 'Disabled',
    SUBMIT_BUTTON_LABEL: 'SAVE',
    CANCEL_BUTTON_LABEL: 'CANCEL'
  },
  AttributeCard: {
    DATA_TYPE: 'Data Type',
    GROUP: 'Group',
    EDIT: 'Edit'
  },
  EditAttributesModalView: {
    TITLE: 'Attributes',
    SUBMIT_BUTTON_LABEL: 'Save',
    CANCEL_BUTTON_LABEL: 'Cancel',
    ERROR_RESPONSE_DESCRIPTION: "Sorry, we're having some technical issues. Try again later.",
    ITEMS_VALIDATION_ERROR_RESPONSE_DESCRIPTION:
      "Sorry, we're having some technical issues. Please, contact the Support team sending this error number: 41",
    GO_BACK_BUTTON_LABEL: 'GO BACK',
    CLOSE_BUTTON_LABEL: 'Close',
    REQUIRED_FIELD_MESSAGE: 'This field is required',
    INVALID_DATE_MESSAGE: 'Invalid date'
  },
  StatusTableCellView: {
    ENABLED: 'Enabled',
    DISABLED: 'Disabled'
  },
  LoadProductsCounterUseCase: {
    UNABLE_LOAD_PRODUCTS_COUNT: 'Unable to load products count'
  },
  AutoCompleteSearchView: {
    SEARCH: 'Search',
    SEARCH_SKU_ONLY: 'Search by SKU',
    SEARCH_SKU_AND_TITLE: 'Search by typing or pasting a list of product titles or SKUs',
    CLEAR_ALL: 'Clear All'
  },
  AutoCompleteInfiniteScroll: {
    NOT_FOUND: 'Not found'
  },
  EditProductTableAction: {
    ALL_FIELDS_DISABLED_TOOLTIP: 'You need to select at least one product to enable bulk editing',
    ONE_FIELD_SELECTED_TOOLTIP:
      'You can only update one information at a time. Save or cancel changes to keep editing your products',
    ENABLED: 'Enabled',
    DISABLED: 'Disabled'
  },
  EditProductTableActionView: {
    CANCEL_BUTTON_LABEL: 'Cancel',
    SAVE_BUTTON_LABEL: 'Save changes',
    SELECT_PLACEHOLDER: 'Change status',
    ENABLED: 'Enabled',
    DISABLED: 'Disabled',
    HAS_SELECTED:
      'You can only update one information at a time. Save or cancel changes to keep editing your products.',
    CHANGE_MANUFACTURER: 'Change manufacturer',
    PRODUCT_COUNTER: '{totalProductsSelected} product(s) selected'
  },
  DeleteProductImageUseCase: {
    UNABLE_TO_DELETE_IMAGE_PRODUCT: 'Unable to delete image product',
    SUCCESS_NOTIFICATION: 'Product image deleted'
  },
  UpdateBulkProductsStatusUseCase: {
    SUCCESS_NOTIFICATION: 'SKUs Status Updated',
    FAILED_NOTIFICATION: 'product(s) failed to update their',
    ERROR_NOTIFICATION: 'Unable to update products',
    SEE_PRODUCTS: 'Copy SKU(s)',
    MAX_SELECTED_ERROR:
      'PIM supports bulk editing for up to 150 products at once. Please, unselect some products.'
  },
  UpdateBulkProductsManufacturerUseCase: {
    SUCCESS_NOTIFICATION: 'SKUs manufacturer updated',
    FAILED_NOTIFICATION: 'SKUs update failed',
    ERROR_NOTIFICATION: 'Unable to update products',
    SEE_PRODUCTS: 'Copy SKU(s)',
    MAX_SELECTED_ERROR:
      'PIM supports bulk editing for up to 150 products at once. Please, unselect some products.'
  },
  SearchProductButton: {
    SEARCH: 'Search'
  },
  DownloadCsvPlpView: {
    BUTTON_LABEL: 'DOWNLOAD CSV',
    DOWNLOAD_ALL: 'DOWNLOAD ALL',
    TOOLTIP_DOWNLOAD_ALL: 'You will download a CSV with {totalNumber} product(s)',
    TOOLTIP_MAX_PRODUCTS:
      'You can’t download more than 2000 products at once. Use the filters and search bar to narrow your search down.',
    DOWNLOADING_CSV_LABEL: 'Downloading'
  },
  AttributesDownloadModalUseCase: {
    ERROR_LOAD_TOP_CATEGORIES: 'Unable to load attributes',
    PROCESSING_DOWNLOAD: 'Processing Download',
    DOWNLOAD_REQUEST_FAILED: 'Download Request failed'
  },
  ModalExportCsvView: {
    CANCEL_BUTTON: 'CANCEL',
    DOWNLOAD_BUTTON: 'DOWNLOAD',
    MODAL_TITLE: 'Download',
    COUNT_PRODUCTS: 'Products in queue',
    SELECT_FIELDS: 'Select fields',
    EMPTY_PRODUCTS_ERROR: 'You must have at least one product in the queue.',
    MAX_PRODUCTS_ERROR: 'Amount of more than 2000 products exceeded.'
  },
  CheckboxAttributesView: {
    ALL_FIELDS: 'All fields',
    NON_EDITABLE: 'Non-editable',
    LEGACY: ' (Legacy)'
  },
  CountrySelector: {
    INPUT_LABEL: 'Select country'
  },
  VendorZoneSelector: {
    INPUT_LABEL: 'Vendor'
  },
  DownloadCsvUseCase: {
    SUCCESS: 'Processing finished',
    ERROR: 'Processing failed'
  },
  BulkOperationsPage: {
    PAGE_TITLE: 'Bulk Editor',
    DOWNLOADS_TAB: 'Downloads',
    UPLOADS_TAB: 'Uploads',
    HISTORY_CARD: 'History'
  },
  BulkDownloadTable: {
    HEADER_COLUMN_DATE: 'Date',
    HEADER_COLUMN_STATUS: 'Status',
    HEADER_COLUMN_DESCRIPTION: 'Description',
    HEADER_COLUMN_ERRORDESCRIPTION: 'Error Description',
    HEADER_COLUMN_UPLOADDESCRIPTION: 'Upload Description',
    HEADER_COLUMN_COUNTRY: 'Country',
    HEADER_COLUMN_TOTALROWS: 'Total rows',
    HEADER_COLUMN_FAILEDROWS: 'Failed rows',
    HEADER_COLUMN_DOWNLOAD: 'Action',
    STATUS_FAILED: 'Failed',
    STATUS_FAILED_IMPORT_UPLOAD: 'Failed',
    STATUS_FAILED_ROWS: 'failed rows',
    STATUS_DONE: 'Done',
    STATUS_PROCESSING: 'Processing',
    STATUS_COMPLETED: 'Completed',
    STATUS_PARTIALLY_UPDATED: 'Partially Updated',
    STATUS_PARTIALLY_UPDATED_ROWS: 'failed rows',
    FILE_UNAVAILABLE: 'File unavailable'
  },
  UploadsTabView: {
    BUTTON: 'Upload File',
    TITLE: 'History'
  },
  SkuTableCell: {
    TOOLTIP_COPY_TEXT: 'Copied!'
  },
  UploadCsvModalView: {
    TITLE: 'Upload CSV',
    DRAG_AND_DROP_MESSAGE: 'Drag and drop the CSV file here or',
    SELECT_FILE_BUTTON: 'Select CSV file',
    DESCRIPTION: 'Upload description',
    BUTTON_CANCEL: 'Cancel',
    BUTTON_UPLOAD: 'Upload CSV',
    BUTTON_TEMPLATE: 'Download template',
    ONLY_CSV_SUPPORT: 'We only support CSV files.',
    COUNTRY_SELECTOR_COUNTRY: 'Country',
    MODAL_TEXT:
      "Select a CSV file you want to upload and submit multiple product updates at once. Please make sure you're following the ",
    UPLOAD_GUIDELINES: 'upload guidelines',
    DESCRIPTION_PLACEHOLDER: 'Insert text here...'
  },
  AlertCountryChangeView: {
    TITLE: 'Are you sure you want to open a different country?',
    ALERT_MESSAGE_1: 'If you open the link from ',
    ALERT_MESSAGE_2:
      ', you will start seeing products from that country and will lose the filters previously applied.',
    CANCEL_BUTTON: 'CANCEL',
    OPEN_BUTTON: 'YES, OPEN'
  },
  CsvTableView: {
    UPLOAD_TITLE: 'No uploads',
    UPLOAD_DESCRIPTION: 'Your uploaded files will be displayed here.',
    DOWNLOAD_TITLE: 'No downloads',
    DOWNLOAD_DESCRIPTION: 'Your download files will be displayed here.'
  },
  ZoneManagementView: {
    ZONE_SELECTOR_VENDOR_LABEL: 'Vendor',
    ZONE_SELECTOR_COUNTRY_LABEL: 'Country:'
  },
  FormikTabWrapper: {
    PRODUCT_QUANTITY_INFORMATION: 'This Product has {count} {attributeType}',
    LEGACY: 'Legacy Attributes',
    DYNAMIC: 'Dynamic Attributes',
    NO_RESULT: 'This product has no {attributeType}'
  },
  Attributes: {
    VENDOR_NAME: 'Vendor name',
    VENDOR_ID: 'Vendor ID',
    SERVICE_MODEL: 'Service model',
    NON_EDITABLE_TOOLTIP: "This field is essential for the CSV upload so it can't be unselected."
  },
  AutocompleteInputView: {
    NOT_FOUND: 'Vendor not found',
    SEARCH_BY: ' Search by display/trade name'
  },
  CsvGuidelinesModalView: {
    TITLE: 'CSV guidelines',
    SUB_TITLE: "In order to have a successful upload, make sure you're following these rules:",
    MAX_PRODUCTS_GUIDE: 'Upload a maximum of 2,000 products per file.',
    MANDATORY_COLUMNS_GUIDE: 'The CSV columns ProductId and SKU are mandatory.',
    CSV_SEPARATOR_GUIDE: 'The CSV separator must be comma ( , ), not semicolon ( ; ).',
    DOUBLE_QUOTES_GUIDE: 'Each CSV cell data must be involved by double quotes ( “ “ ).',
    FULL_DOCUMENTATION: 'Read full documentation',
    GOT_IT: 'Got it'
  },
  UpdateBulkProductsUseCase: {
    UPDATE_STARTED: 'Updating {totalProductsToUpdate} product(s). This can take up to a minute.',
    SUCCESS_NOTIFICATION:
      'You updated the {attributeName} of {totalSuccessProductsUpdated} product(s) to {newValue}.',
    FAILED_NOTIFICATION: '{totalFailedProducts} product(s) failed to update their {attributeName}.',
    MANUFACTURER: 'Manufacturer',
    COPY_PRODUCTS: 'Copy SKU(s)',
    STATUS: 'Status'
  },
  ProductTableSidebarView: {
    LOADING: 'Loading...',
    RESULTS: 'Showing {totalProducts} results',
    CLEAR: 'Clear',
    APPLY: 'Apply'
  }
};

export default en;
