import { usePimSidebar } from 'partner-portal-product-shared-features';
import React from 'react';

interface Props {
  language: string;
}

const SideBarWrapper = ({ language }: Props): JSX.Element => {
  const { updateSideBarLang } = usePimSidebar();

  React.useEffect(() => {
    if (language) {
      updateSideBarLang(language);
    }
  }, [language, updateSideBarLang]);

  return null;
};

export default SideBarWrapper;
