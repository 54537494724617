import {
  useFeatureToggle as useFeatureToggleHook,
  useFeatureToggleService
} from 'admin-portal-shared-services';

const isToggleEnabled = (toggleName: string): boolean => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useFeatureToggleService().isFeatureEnabled(toggleName);
};

const useFeatureToggle = (toggleName: string): boolean[] => {
  const toggleStatus = useFeatureToggleHook(toggleName);

  return [toggleStatus];
};

const PROMISE_TIMEOUT = 200;

const hasLoadedOptimizely = async () => {
  if (window.Cypress) return true;

  if (typeof window.services.FeatureToggleOptimizely === 'undefined') {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve(hasLoadedOptimizely());
      }, PROMISE_TIMEOUT);
    });
  }

  return window.services.FeatureToggleOptimizely.optimizely.onReady();
};

export { useFeatureToggle, isToggleEnabled, hasLoadedOptimizely };
