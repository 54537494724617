import MessageMap from '../domains/MessageMap';

const koKR: MessageMap = {
  ProductListRoute: {
    PAGE_LAYOUT_CAPTION: '제품',
    PAGE_LAYOUT_TITLE: '제품'
  },
  ZoneManagementView: {
    ZONE_SELECTOR_VENDOR_LABEL: '벤더:',
    ZONE_SELECTOR_COUNTRY_LABEL: '국가:'
  },
  AddProductButton: {
    ADD_PRODUCT_BUTTON_LABEL: '+ 제품 추가',
    SELECT_VENDOR_TOOLTIP: '제품을 생성하기 전에 공급업체를 선택하십시오.'
  },
  AutoCompleteSearchView: {
    SEARCH: '검색',
    SEARCH_SKU_ONLY: 'SKU로 검색',
    SEARCH_SKU_AND_TITLE: '제품 제목 또는 SKU 목록을 입력하거나 붙여넣어 검색',
    CLEAR_ALL: '모두 지우기'
  },
  ImageCard: {
    TOOLTIP_ERROR_MESSAGE: '이미지 도메인 또는 주소가 잘못되었습니다. 새 이미지를 업로드하세요.'
  },
  ImageCardView: {
    UPLOAD_PRODUCT_IMAGE: '제품 이미지 업로드',
    THUMBNAILS_INFORMATION: '썸네일은 JPG, PNG 형식이어야 하며 1MB 미만이어야 합니다.',
    COPY_URL_TOOLTIP: '사진 URL이 복사되었습니다!'
  },
  SearchProductButton: {
    SEARCH: '검색'
  },
  UpdateBulkProductsManufacturerUseCase: {
    SUCCESS_NOTIFICATION: 'SKU 제조업체 업데이트됨',
    FAILED_NOTIFICATION: 'SKU 업데이트 실패',
    ERROR_NOTIFICATION: '제품을 업데이트할 수 없습니다.',
    MAX_SELECTED_ERROR:
      'PIM은 한 번에 최대 150개의 제품에 대한 대량 편집을 지원합니다. 일부 제품을 선택 취소하십시오.'
  },
  ProductFilters: {
    FILTER: '필터',
    FILTER_BY_PRODUCT_TITLE: '제품명으로 검색',
    PRODUCT_TITLE_FILTER: '상품명',
    PRODUCT_STATUS_FILTER: '상태',
    PRODUCT_STATUS_FILTER_ENABLED: '활성화됨',
    PRODUCT_STATUS_FILTER_DISABLED: '비활성화 됨',
    PRODUCT_HAS_ERROR_FILTER: '오류가 있는 항목',
    PRODUCT_HAS_ERROR_FILTER_ENABLED: '예',
    PRODUCT_HAS_ERROR_FILTER_DISABLED: '아니요',
    PRODUCT_CREATED_DATE_FILTER: '만든 날짜',
    PRODUCT_START_DATE_FILTER: '시작일',
    PRODUCT_END_DATE_FILTER: '종료일',
    PRODUCT_COUNTRY_FILTER: '국가',
    PRODUCT_MANUFACTURER_FILTER: '제조사',
    PRODUCT_MANUFACTURER_FILTER_SEARCH: '검색',
    PRODUCT_HAS_IMAGE_FILTER: '제품 이미지',
    PRODUCT_HAS_IMAGE_FILTER_ENABLED: '이미지 포함',
    PRODUCT_HAS_IMAGE_FILTER_DISABLED: '이미지 없음'
  },
  FiltersModal: {
    APPLY_BUTTON: '적용',
    CANCEL_BUTTON: '취소'
  },
  ProductTable: {
    HEADER_COLUMN_TITLE: '상품명',
    HEADER_COLUMN_SKUNUMBER: 'SKU',
    HEADER_COLUMN_BRAND: '브랜드',
    HEADER_COLUMN_VENDOR: '공급업체 이름',
    HEADER_COLUMN_MODEL: '서비스 모델',
    HEADER_COLUMN_CATEGORY: '클래스',
    HEADER_COLUMN_STATUS: '상태',
    HEADER_COLUMN_LASTUPDATED: '최종 수정일',
    HEADER_COLUMN_CREATEDAT: '생성일',
    HEADER_COLUMN_COUNTRY: '국가',
    HEADER_COLUMN_MANUFACTURER: '제조사',
    TOOLTIP_COLUMN_SKUNUMBER:
      'SKU(Stock Keeping Unit, 재고 보관 단외)는 개별 판매 품목을 구별하는 데 사용되는 고유 식별자입니다.',
    TOOLTIP_COLUMN_BRAND: '제품 브랜드 이름',
    TOOLTIP_COLUMN_VENDOR: '공급업체 이름',
    TOOLTIP_COLUMN_MODEL: '모델명',
    TOOLTIP_COLUMN_CATEGORY: '제품 분류',
    TOOLTIP_COLUMN_LASTUPDATED: '제품이 마지막으로 수정된 날짜입니다.',
    TOOLTIP_COLUMN_CREATEDAT: '제품 생성일',
    TOOLTIP_COLUMN_COUNTRY: '제품 원산지 국가',
    TOOLTIP_COLUMN_MANUFACTURER: '제조업체명',
    EMPTY_VENDOR_MESSAGE: '사용자에게 연결된 공급업체가 없습니다',
    MISSING_INFORMATION: '없는 정보'
  },
  ProductCreatedDateFilterView: {
    INVALID_DATE_ERROR: '잘못된 날짜',
    MAX_START_DATE_ERROR: '시작 날짜는 현재 날짜 이후일 수 없습니다',
    MAX_START_DATE_ERROR_WITH_END_DATE: '시작 날짜는 종료 날짜 이후일 수 없습니다',
    MIN_END_DATE_ERROR_WITH_START_DATE: '종료 날짜는 시작 날짜 이전이 아니어야 합니다',
    MIN_END_DATE_ERROR: '종료 날짜는 1899년 1월 1일 이전이어야 합니다.',
    MIN_START_DATE_ERROR: '시작 날짜는 1899년 1월 1일 이전이어야 합니다.',
    MAX_END_DATE_ERROR: '종료 날짜는 현재 날짜 이후일 수 없습니다',
    START_DATE: '시작일',
    END_DATE: '종료일'
  },
  StatusTableCellView: {
    ENABLED: '활성화됨',
    DISABLED: '비활성화 됨'
  },
  EditProductTableAction: {
    ALL_FIELDS_DISABLED_TOOLTIP: '일괄 수정을 사용하려면 하나 이상의 제품을 선택해야 합니다.',
    ONE_FIELD_SELECTED_TOOLTIP:
      '한 번에 하나의 정보만 업데이트할 수 있습니다. 제품을 계속 편집하려면 변경 사항을 저장하거나 취소하세요.',
    ENABLED: '활성화됨',
    DISABLED: '비활성화 됨'
  },
  EditProductTableActionView: {
    CANCEL_BUTTON_LABEL: '취소',
    SAVE_BUTTON_LABEL: '변경 사항을 저장하다',
    SELECT_PLACEHOLDER: '상태 변경',
    ENABLED: '활성화됨',
    DISABLED: '비활성화 됨',
    HAS_SELECTED:
      '한 번에 하나의 정보만 업데이트할 수 있습니다. 제품을 계속 편집하려면 변경 사항을 저장하거나 취소하십시오.',
    CHANGE_MANUFACTURER: '제조업체 변경',
    PRODUCT_COUNTER: '{totalProductsSelected} 개의 제품이 선택됨'
  },
  DownloadCsvPlpView: {
    BUTTON_LABEL: 'CSV 다운로드',
    DOWNLOAD_ALL: '모두 다운로드',
    TOOLTIP_DOWNLOAD_ALL: '{totalNumber} 개의 제품이 포함된 CSV를 다운로드합니다.',
    TOOLTIP_MAX_PRODUCTS:
      '한 번에 2000개 이상의 제품을 다운로드할 수 없습니다. 필터와 검색창을 사용하여 검색 범위를 좁히세요.',
    DOWNLOADING_CSV_LABEL: '다운로드 중'
  },
  ProductTablePagination: {
    ROWS_PER_PAGE_LABEL: '페이지당 행'
  },
  ProductManufacturerFilter: {
    EMPTY_MANUFACTURER: '결과를 보려면 먼저 무언가를 삽입하십시오.'
  },
  FilterChipList: {
    PRODUCT_TITLE_FILTER: '상품명',
    PRODUCT_STATUS_FILTER: '상태',
    PRODUCT_STATUS_FILTER_ENABLED: '활성화됨',
    PRODUCT_STATUS_FILTER_DISABLED: '비활성화 됨',
    PRODUCT_CREATED_DATE_FILTER: '생성일',
    PRODUCT_START_DATE_FILTER: '시작일',
    PRODUCT_END_DATE_FILTER: '종료일',
    WITH_PROBLEMS: '오류가 있는 항목'
  },
  FilterChipListView: {
    PRODUCT_START_DATE_FILTER_CHIP: '~에서',
    PRODUCT_END_DATE_FILTER_CHIP: '~까지',
    PRODUCT_START_AND_END_DATE_FILTER: '에게'
  },
  ModalExportCsvView: {
    CANCEL_BUTTON: '취소',
    DOWNLOAD_BUTTON: '다운로드',
    MODAL_TITLE: '다운로드',
    COUNT_PRODUCTS: '개의 제품이 대기열에 있습니다.',
    SELECT_FIELDS: '필드 선택',
    EMPTY_PRODUCTS_ERROR: '대기열에 하나 이상의 제품이 있어야 합니다.',
    MAX_PRODUCTS_ERROR: '상품수량이 2000개를 넘어섰습니다.'
  },
  CheckboxAttributesView: {
    ALL_FIELDS: '모든 분야',
    NON_EDITABLE: '편집 불가',
    LEGACY: ' (유산)'
  },
  ModalExportCsv: {
    DESCRIPTION: '설명',
    IMAGE: '이미지 URL'
  },
  CreateProductModalView: {
    TITLE: '새로운 상품',
    LABEL_SUBMIT_BUTTON: '시작',
    SERVICE_MODEL: '서비스 모델',
    TITLE_HEADER: '유형 정의',
    SUB_TITLE_HEADER: '생성하려는 서비스 모델 유형을 선택합니다.',
    SELECT_CLASS: '수업 선택',
    SELECT_CLASS_DESCRIPTION: '제품의 속성 정의를 위해서 아래 목록에서 클래스를 선택하세요.',
    CLASS: '클래스',
    LABEL_CANCEL_BUTTON: '취소',
    NEXT: '다음',
    LABEL_BACK_BUTTON: '돌아가기',
    SELECTED_TEXT: '선택된',
    SELECTED_CLASS: '선택된: {name}',
    SUPPLIER_LABEL: '공급업체 선택',
    SELECT_A_CLASS: '수업 선택',
    SELECT_CLASS_SUBTITLE: '이 새 제품은 선택한 클래스의 모든 속성 필드를 상속합니다.'
  },
  CreateProductFormView: {
    CREATE: '생성',
    DISCARD: '버리기',
    VARIATIONS: '변형',
    DETAILS: '상세',
    NEW_PRODUCT: '새로운 상품',
    REQUIRED_FIELD_MESSAGE: '이 필드는 필수 항목입니다',
    INVALID_DATE_MESSAGE: '잘못된 날짜',
    INVALID_NUMBER_MESSAGE: '숫자는 0보다 크거나 같아야 합니다'
  },
  ProductDetailsTabView: {
    MESSAGE_HEADER: '제품 정보 및 속성 제출.',
    PRODUCT_ALREADY_TO_BE_PUBLISHED: '이제 이 제품은 게시할 수 있습니다.'
  },
  ProductInformationCardView: {
    PRODUCT_INFORMATION_CARD_TITLE: '제품 정보',
    SUPPLIER_LABEL: '공급업체 선택',
    CATEGORY_LABEL: '클래스',
    SKU_INPUT_LABEL: 'SKU *',
    SKU_TOOLTIP_MESSAGE:
      'SKU(Stock Keeping Unit, 재고 보관 단외)는 개별 판매 품목을 구별하는 데 사용되는 고유 식별자입니다.',
    PRODUCT_TITLE_TOOLTIP_MESSAGE:
      '이것은 우리 마켓플레이스에 나타날 이름입니다. 예: 맥주 이름 355ml 캔',
    PRODUCT_TITLE_LABEL: '제품명 *',
    PRODUCT_DESCRIPTION_LABEL: '제품 설명',
    PRODUCT_VARIATION_ALREADY_EXIST:
      '이 제품 변형은 이미 데이터베이스에 있습니다. 속성 값이 동일한 다른 제품 변형인 경우 제목과 SKU가 달라야 합니다.',
    THE_TITLE_ALREADY_BEING_USED: '제목이 이미 사용 중입니다.',
    THE_SKU_ALREADY_EXISTS: '이 SKU는 이미 존재합니다.',
    FIELD_STATUS_LABEL: '상태'
  },
  VendorSelector: {
    INPUT_LABEL: '공급업체 선택',
    INPUT_PLACEHOLDER: '공급업체 선택'
  },
  ProductWizardModeSelectorView: {
    ABI_MODE_SUBTITLE: 'BEES에서 판매 및 배송합니다.',
    ONE_P_MODE_TITLE: '1P',
    ONE_P_MODE_SUBTITLE: 'BEES에서 판매 및 배송합니다.',
    THREE_P_MODE_TITLE: '3P',
    THREE_P_MODE_SUBTITLE: '공급업체에서 판매 및 배송',
    VARIANT_MODE_TITLE: '새로운 변형',
    VARIANT_MODE_SUBTITLE: '제품 변형 만들기',
    THREE_P_L_MODE_TITLE: '3PL',
    THREE_P_L_MODE_SUBTITLE: 'ABI에서 판매하고 공급업체에서 제공',
    ONE_P_THREE_L_MODE_TITLE: '1P3L',
    ONE_P_THREE_L_MODE_SUBTITLE: 'ABI에서 판매하고 공급업체에서 제공'
  },
  FormikInputBoolean: {
    YES: '예',
    NO: '아니오',
    ENABLED: '활성화됨',
    DISABLED: '비활성화 됨'
  },
  ProductAttributesCardView: {
    ATTRIBUTES: '속성'
  },
  FifoAttributesValue: {
    YELLOW: '노란색',
    REGULAR: '정기적인',
    ORANGE: '주황색'
  },
  ProductImageUploaderView: {
    THUMBNAILS_MUST_JPG_PNG_FORMAT: '썸네일은 JPG, PNG 형식으로 1MB 미만이어야 합니다.',
    IMAGE_FORMAT_NOT_SUPPORTED:
      '지원되지 않는 이미지 형식입니다. .png 또는 .jpeg 형식의 이미지만 업로드하세요.',
    IMAGE_SIZE_NOT_SUPPORTED: '이미지 크기 제한을 초과했습니다. 1MB 이하의 이미지를 업로드하세요.',
    EDIT_LABEL_BUTTON: '수정',
    UPLOAD_MAIN_PRODUCT_IMAGE: '주요 제품 이미지를 업로드합니다.',
    ZOOM_MODAL_TITLE: '제품 이미지',
    COPY_BUTTON_TEXT: '사진 URL 복사',
    SAVE_BUTTON: '저장',
    CANCEL_BUTTON: '취소',
    COPY_URL_TOOLTIP: '사진 URL이 복사되었습니다.'
  },
  ProductConfirmationModalView: {
    PUBLISH_LABEL_BUTTON: '게시',
    CREATE_LABEL_BUTTON: '생성',
    CANCEL_LABEL_BUTTON: '취소',
    EDIT_LABEL_BUTTON: '수정',
    DISCARD_LABEL_BUTTON: '버리기',
    EDIT_MODAL_TITLE: '제품이 이미 존재합니다',
    PUBLISH_MODAL_TITLE: '제품 게시',
    CREATE_MODAL_TITLE: '제품 만들기',
    DISCARD_MODAL_TITLE: '이 제품을 삭제하시겠습니까?'
  },
  UpdateProductImageUseCase: {
    UNABLE_TO_UPDATE_IMAGE_PRODUCT: '이미지 제품을 업데이트할 수 없습니다.',
    IMAGE_SUCCESSFULLY_UPDATE: '이 제품 이미지를 성공적으로 업데이트했습니다!'
  },
  PublishProductModalActions: {
    SUBTITLE_TEXT_LABEL_CREATE: '이 제품을 생성하시겠습니까?'
  },
  DiscardProductModalActions: {
    SUBTITLE_TEXT_LABEL: '추가된 모든 정보가 손실됩니다.'
  },
  BulkOperationsPage: {
    PAGE_TITLE: '일괄 편집기',
    DOWNLOADS_TAB: '다운로드',
    UPLOADS_TAB: '업로드',
    HISTORY_CARD: '이력'
  },
  BulkDownloadTable: {
    HEADER_COLUMN_DATE: '일자',
    HEADER_COLUMN_STATUS: '상태',
    HEADER_COLUMN_DESCRIPTION: '설명',
    HEADER_COLUMN_ERRORDESCRIPTION: '오류 상세 설명',
    HEADER_COLUMN_UPLOADDESCRIPTION: '설명 업로드',
    HEADER_COLUMN_COUNTRY: '국가',
    HEADER_COLUMN_TOTALROWS: '총 행',
    HEADER_COLUMN_FAILEDROWS: '실패한 행',
    HEADER_COLUMN_DOWNLOAD: '동작',
    STATUS_FAILED: '실패',
    STATUS_FAILED_IMPORT_UPLOAD: '실패',
    STATUS_FAILED_ROWS: '실패한 행',
    STATUS_DONE: '완료',
    STATUS_PROCESSING: '실행 중',
    STATUS_COMPLETED: '완료',
    STATUS_PARTIALLY_UPDATED: '일부가 갱신되었습니다.',
    STATUS_PARTIALLY_UPDATED_ROWS: '실패한 행',
    FILE_UNAVAILABLE: '파일을 사용할 수 없음'
  },
  UploadsTabView: {
    BUTTON: '업로드 파일',
    TITLE: '이력'
  },
  CsvTableView: {
    UPLOAD_TITLE: '업로드 없음',
    UPLOAD_DESCRIPTION: '업로드한 파일이 여기에 표시됩니다.',
    DOWNLOAD_TITLE: '다운로드 없음',
    DOWNLOAD_DESCRIPTION: '다운로드 한 파일이 여기에 표시됩니다.'
  },
  VendorZoneSelector: {
    INPUT_LABEL: '공급업체'
  },
  UploadCsvModalView: {
    TITLE: 'CSV 업로드',
    DRAG_AND_DROP_MESSAGE: 'CSV 파일을 여기에 끌어다 놓거나',
    SELECT_FILE_BUTTON: 'CSV 파일 선택',
    DESCRIPTION: '설명 업로드',
    BUTTON_CANCEL: '취소',
    BUTTON_UPLOAD: 'CSV 업로드',
    ONLY_CSV_SUPPORT: 'CSV 파일만 지원합니다.',
    COUNTRY_SELECTOR_COUNTRY: '국가',
    MODAL_TEXT: '업로드할 CSV 파일을 선택하고 한 번에 여러 제품 업데이트를 제출하십시오. ',
    UPLOAD_GUIDELINES: '업로드 가이드라인을 따르고 있는지 확인하세요.',
    DESCRIPTION_PLACEHOLDER: '여기에 텍스트 삽입...'
  },
  Attributes: {
    VENDOR_NAME: '벤더명',
    VENDOR_ID: '벤더 아이디',
    SERVICE_MODEL: '서비스 모델',
    NON_EDITABLE_TOOLTIP: '이 필드는 CSV 업로드에 필수이므로 선택을 취소할 수 없습니다.'
  },
  AutocompleteInputView: {
    NOT_FOUND: '공급업체를 찾을 수 없음',
    SEARCH_BY: '표시명/상표명으로 검색'
  },
  CsvGuidelinesModalView: {
    TITLE: 'CSV 가이드라인',
    SUB_TITLE: '성공적으로 업로드하려면 다음 규칙을 따르고 있는지 확인하세요:',
    MAX_PRODUCTS_GUIDE: '파일당 최대 2,000개의 제품을 업로드합니다.',
    MANDATORY_COLUMNS_GUIDE: 'CSV 열 ProductId 및 SKU는 필수입니다.',
    CSV_SEPARATOR_GUIDE: 'CSV 구분 기호는 세미콜론( ; )이 아니라 쉼표( , )여야 합니다.',
    DOUBLE_QUOTES_GUIDE: '각 CSV 셀 데이터는 큰따옴표( " " )로 묶어야 합니다.',
    FULL_DOCUMENTATION: '전체 문서 읽기',
    GOT_IT: '알았다'
  },
  UpdateBulkProductsUseCase: {
    UPDATE_STARTED:
      '{totalProductsToUpdate}개의 제품을 업데이트 중입니다. 최대 1분이 소요될 수 있습니다.',
    SUCCESS_NOTIFICATION:
      '{totalSuccessProductsUpdated}개 제품의 {attributeName}을 {newValue}으로 업데이트했습니다.',
    FAILED_NOTIFICATION:
      '{totalFailedProducts} 제품이 {attributeName}을(를) 업데이트하지 못했습니다.',
    MANUFACTURER: '제조사',
    COPY_PRODUCTS: 'SKU 복사',
    STATUS: '상태'
  },
  ProductTableSidebarView: {
    LOADING: '로드 중...',
    RESULTS: '{totalProducts} 결과 표시 중',
    CLEAR: '지우기',
    APPLY: '적용하다'
  }
};

export default koKR;
